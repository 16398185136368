import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

// models
import {WaterProtocol} from "../models/water-protocol/water-protocol";
import {WaterProtocolData} from "../models/water-protocol/water-protocol-data";
import {ApiMessage} from "../models/api-message";

@Injectable({
    providedIn: 'root'
})
export class WaterProtocolService {

    constructor(protected http: HttpClient) {
    }

    // get one water protocol for a logged admin/member/user form API
    public getWaterProtocol(id: number): Observable<WaterProtocol> {
        const url = `${environment.api}/water-protocol/${id}`;
        return this.http.get<WaterProtocol>(url);
    }

    // add water protocol for a logged admin/member to API
    public addWaterProtocol(userType: string, data: WaterProtocolData): Observable<ApiMessage> {
        const url = this.getURLbyUserType(userType);
        return this.http.post<ApiMessage>(url, data);
    }

    // update water protocol in DB for logged admin/member only
    public updateWaterProtocol(userType: string, id: number, data: Partial<WaterProtocolData>): Observable<ApiMessage> {
        const url = this.getURLbyUserType(userType);
        return this.http.put<ApiMessage>(`${url}/${id}`, data);
    }

    // delete water protocol - only for admin
    public delete(id: number): Observable<ApiMessage> {
        return this.http.delete<ApiMessage>(`${environment.api}/admin-water-protocol/${id}`);
    }

    // get API URL by a logged admin/member
    private getURLbyUserType(userType: string): string {
        let url = '';
        switch (userType) {
            case 'admin':
                url = `${environment.api}/admin-water-protocol`;
                break;
            case 'member':
                url = `${environment.api}/member-water-protocol`;
                break;
        }
        return url;
    }
}
