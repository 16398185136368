import {Component} from '@angular/core';

@Component({
    selector: 'app-working-times',
    templateUrl: './working-times.component.html',
    styleUrl: './working-times.component.scss'
})
export class WorkingTimesComponent {

}
