<div class="pt-2">
    <div *ngIf="isLoading; else content">
        <div class="d-flex justify-content-center py-2">
            <mat-spinner></mat-spinner>
        </div>
    </div>
    <ng-template #content>
        <form [formGroup]="userNameForm">
            <mat-form-field class="three-quarters-width" appearance="outline">
                <mat-label>Your name or nick name</mat-label>
                <input matInput formControlName="name" name="name" type="text" placeholder="Name or nick name"
                       #displayName>
                <mat-hint align="end">
                    {{ displayName.value.length }} / {{ userNameMaxLength }}
                </mat-hint>
                <mat-error *ngIf="name.errors?.['required']">
                    Please enter a name or nick name.
                </mat-error>
                <mat-error *ngIf="name.value.length < userNameMinLength && !name.errors?.['required']">
                    A minimum length of name is {{ userNameMinLength }} characters.
                </mat-error>
                <mat-error *ngIf="name.value.length > userNameMaxLength">
                    A maximum length of name is {{ userNameMaxLength }} characters.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="three-quarters-width pt-2" appearance="outline">
                <mat-label>Company name</mat-label>
                <input matInput formControlName="company_name" name="company_name" type="text"
                       placeholder="Company name"
                       #companyName>
                <mat-hint align="end">
                    {{ companyName.value.length }} / {{ companyNameMaxLength }}
                </mat-hint>
                <mat-error *ngIf="company_name.errors?.['required']">
                    Please enter a name or nick name.
                </mat-error>
                <mat-error
                    *ngIf="company_name.value.length < companyNameMinLength && !company_name.errors?.['required']">
                    A minimum length of the company name is {{ companyNameMinLength }} characters.
                </mat-error>
                <mat-error *ngIf="company_name.value.length > companyNameMaxLength">
                    A maximum length of the company name is {{ companyNameMaxLength }} characters.
                </mat-error>
            </mat-form-field>
        </form>
    </ng-template>
</div>
