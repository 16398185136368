import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {AuthService} from "../../../shared/services/auth.service";
import {Router} from "@angular/router";
import {MatSort, Sort} from "@angular/material/sort";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";

// modal
import {MatDialog} from "@angular/material/dialog";
import {DeleteModalComponent} from "../../components/delete-modal/delete-modal.component";
import {IntercomCodeShowModalComponent} from "../intercom-code-show-modal/intercom-code-show-modal.component";

// service
import {IntercomCodesService} from "../../../shared/services/intercom-codes.service";

// models
import {IntercomCodes} from "../../../shared/models/intercom-codes/intercom-codes";
import {IntercomCode} from "../../../shared/models/intercom-codes/intercom-code";


@Component({
    selector: 'app-intercom-codes-list',
    templateUrl: './intercom-codes-list.component.html',
    styleUrls: ['./intercom-codes-list.component.scss', './../../secure.component.scss']
})
export class IntercomCodesListComponent implements OnInit, AfterViewInit {

    @ViewChild(MatSort) public sort!: MatSort;
    @ViewChild(MatPaginator) public paginator!: MatPaginator;

    public isLoading = false;
    public displayedColumns: string[] = ['address', 'number', 'code', 'actions'];
    public intercomCodes$ = new MatTableDataSource<IntercomCode>();
    public pageSizeOptions: number[] = [6, 12, 24, 48];
    public totalRows = 0;
    public pageSize = 6;
    public currentPage = 0;
    public intercomCodesSort = 'id';
    public intercomCodesDirection = 'desc';
    public searchFilter = '';

    constructor(protected authService: AuthService,
                private router: Router,
                private intercomCodesService: IntercomCodesService,
                private dialog: MatDialog) {
    }

    ngAfterViewInit(): void {
        this.intercomCodes$.paginator = this.paginator;
        this.paginator.firstPage();
    }

    ngOnInit(): void {
        this.loadIntercomCodes();
    }

    public pageChanged(event: PageEvent): void {
        this.pageSize = event.pageSize;
        this.currentPage = event.pageIndex + 1;
        this.loadIntercomCodes();
    }

    public sortData(sort: Sort): void {
        switch (sort.active) {
            case 'address':
                this.intercomCodesSort = sort.active;
                this.intercomCodesDirection = sort.direction;
                this.loadIntercomCodes();
                return
            case 'number':
                this.intercomCodesSort = sort.active;
                this.intercomCodesDirection = sort.direction;
                this.loadIntercomCodes();
                return
            case 'code':
                this.intercomCodesSort = sort.active;
                this.intercomCodesDirection = sort.direction;
                this.loadIntercomCodes();
                return
            default:
                return
        }
    }

    public addIntercomCode(): void {
        this.router.navigateByUrl(`secure/intercom-code-add`).then();
    }

    public showIntercomCode(intercomCodeID: number): void {
        this.dialog.open(IntercomCodeShowModalComponent, {
            data: {
                intercomCodeID: intercomCodeID
            }
        }).afterClosed().subscribe(
            (res): void => {
                if (!res) {
                    this.router.navigateByUrl(`secure/intercom-codes`).then();
                }
            }
        );
    }

    public editIntercomCode(id: number): void {
        this.router.navigateByUrl(`secure/intercom-code-update/${id}`).then();
    }

    public removeIntercomCode(intercomCodeID: number): void {
        this.dialog.open(DeleteModalComponent, {
            data: {
                modalService: 'IntercomCode',
                modalName: 'intercom code',
                id: intercomCodeID
            }
        }).afterClosed().subscribe(
            (res): void => {
                this.loadIntercomCodes();
                if (!res) {
                    this.router.navigateByUrl(`secure/intercom-codes`).then();
                }
            }
        );
    }

    public setSearchFilter(): void {
        this.searchFilter.trim().toLowerCase();
        this.loadIntercomCodes();
        this.paginator.firstPage();
    }

    private loadIntercomCodes(): void {
        this.isLoading = true;
        const userType = this.authService.user.type;
        this.intercomCodesService.index(
            userType,
            this.currentPage,
            this.pageSize,
            this.intercomCodesSort,
            this.intercomCodesDirection,
            this.searchFilter
        ).subscribe((intercomCodes: IntercomCodes): void => {
                this.intercomCodes$ = new MatTableDataSource(intercomCodes.data);
                this.intercomCodes$.sort = this.sort;
                this.paginator.length = intercomCodes.meta.total;
                this.paginator.pageIndex = 0;
                this.paginator.pageIndex = intercomCodes.meta.current_page - 1;
                this.isLoading = false;
            }
        );
    }
}
