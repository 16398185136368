import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'app-working-time-save-button',
    templateUrl: './working-time-save-button.component.html',
    styleUrl: './working-time-save-button.component.scss'
})
export class WorkingTimeSaveButtonComponent {

    @Input() public invalid = true;
    @Input() public showMode = false;

    @Output() public save = new EventEmitter<void>();
    @Output() public closeForm = new EventEmitter<void>();

    public saveEmit(): void {
        this.save.emit();
    }

    public closeEmit(): void {
        this.closeForm.emit();
    }
}
