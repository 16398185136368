import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
    selector: 'app-water-protocol-form',
    templateUrl: './water-protocol-form.component.html',
    styleUrl: './water-protocol-form.component.scss'
})
export class WaterProtocolFormComponent implements OnInit {

    @Input() public comment = '';
    @Input() public editMode = false;

    public waterProtocolForm!: FormGroup;

    // validators min/max rules
    public commentMaxLength = 400;

    constructor(protected formBuilder: FormBuilder) {
    }

    ngOnInit(): void {
        this.buildWaterProtocolForm();
        if (this.editMode) {
            setTimeout((): void => {
                this.comments.setValue(this.comment);
            }, 200);
        }
    }

    public get comments(): AbstractControl {
        return this.waterProtocolForm.controls['comments'];
    }

    private buildWaterProtocolForm(): void {
        this.waterProtocolForm = this.formBuilder.group({
            comments: ['', {
                validators: [Validators.maxLength(this.commentMaxLength)]
            }],
        });
    }
}
