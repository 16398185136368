<mat-toolbar class="bg-danger">
    <span>Question <i class="fa-solid fa-question"></i></span>
    <span class="toolbar-spacer"></span>
    <button class="text-white" mat-icon-button matTooltip="Close" matTooltipPosition="below" [mat-dialog-close]="true"
            aria-hidden="false">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>
<mat-dialog-content>
    <div class="container text-center">
        <h4 class="pt-3">Are you sure?</h4>
        <h4>That you want to delete the {{ modalName }} ?</h4>
        <div class="py-2">
            <mat-checkbox color="primary" [(ngModel)]="checked">I'm sure.</mat-checkbox>
        </div>
    </div>
</mat-dialog-content>
<div class="d-flex justify-content-end pb-4">
    <button class="full-width mx-2" mat-raised-button [mat-dialog-close]="true"
            matTooltip="Cancel - close modal" matTooltipPosition="below" aria-hidden="false">
        <i class="fa-solid fa-xmark"></i> NO
    </button>
    <button class="full-width mx-2" mat-raised-button color="warn" (click)="delete(id)"
            matTooltip="Delete" matTooltipPosition="below"
            [disabled]="!checked">YES <i class="fa-solid fa-check"></i></button>
</div>
