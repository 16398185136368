import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";

// models
import {User} from "../../../shared/models/user/user";

@Component({
    selector: 'app-user-form',
    templateUrl: './user-form.component.html',
    styleUrl: './user-form.component.scss'
})
export class UserFormComponent implements OnInit {

    @Input() user!: User

    public isLoading = true;
    public userNameForm!: FormGroup;
    public userNameMinLength = 3;
    public userNameMaxLength = 100;
    public companyNameMinLength = 1;
    public companyNameMaxLength = 100;

    constructor(protected formBuilder: FormBuilder) {
    }

    ngOnInit(): void {
        this.buildUserNameForm();
        setTimeout((): void => {
            this.setUserNames(this.user);
            this.isLoading = false;
        }, 500);
    }

    public get name(): AbstractControl {
        return this.userNameForm.controls['name'];
    }

    public get company_name(): AbstractControl {
        return this.userNameForm.controls['company_name'];
    }

    private buildUserNameForm(): void {
        this.userNameForm = this.formBuilder.group({
            name: ['', {
                validators: [Validators.required,
                    Validators.minLength(this.userNameMinLength), Validators.maxLength(this.userNameMaxLength)]
            }],
            company_name: ['', {
                validators: [Validators.required,
                    Validators.minLength(this.companyNameMinLength), Validators.maxLength(this.companyNameMaxLength)]
            }],
        });
    }

    private setUserNames(user: User): void {
        const {...formData} = user;
        this.userNameForm.patchValue(formData);
    }
}
