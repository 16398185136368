import {Component} from '@angular/core';
import {AuthService} from "../../shared/services/auth.service";

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {

    constructor(protected authService: AuthService) {
    }
}
