import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {WorkingTimeFormComponent} from "../working-time-form/working-time-form.component";

// services
import {WorkingTimeService} from "../../../shared/services/working-time.service";
import {SnackbarService} from "../../../shared/services/snackbar.service";
import {AuthService} from "../../../shared/services/auth.service";

// models
import {ApiMessage} from "../../../shared/models/api-message";

@Component({
    selector: 'app-working-time-save',
    templateUrl: './working-time-save.component.html',
    styleUrl: './working-time-save.component.scss'
})
export class WorkingTimeSaveComponent implements OnInit {

    @ViewChild('data') data!: WorkingTimeFormComponent;

    public userID = 0

    constructor(protected authService: AuthService,
                private workingTimesService: WorkingTimeService,
                private snackbarService: SnackbarService,
                private route: ActivatedRoute,
                private router: Router) {
    }

    ngOnInit(): void {
        this.route.queryParamMap.subscribe((params: ParamMap): void => {
            this.userID = parseInt(params.get('userID') as string);
        });
    }

    public addWorkingTime(): void {
        let userID;
        if (this.authService.user.type === 'admin') {
            userID = this.userID;
        } else userID = this.authService.user.id;
        const workingTimeForm = this.data.workingTimeForm.getRawValue();
        const workingTimeData = {
            user_id: userID,
            start: workingTimeForm.start_date + ' ' + workingTimeForm.hour + ':' + workingTimeForm.minute,
            comment: ''
        };
        this.workingTimesService.createWorkingTime(this.authService.user.type, workingTimeData).subscribe({
            next: (res: ApiMessage): void => {
                this.snackbarService.snackbarCustomComplete(res.message);
            },
            error: (e) => this.snackbarService.snackbarCustomError(e.error.message),
            complete: () => this.closeForm()
        })
    }

    public closeForm(): void {
        this.router.navigateByUrl('/secure/working-times').then();
    }
}
