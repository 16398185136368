<div class="col">
    <div class="card card-image shadow-sm">
        <img class="img-hover" [src]="imageLink" alt="preview" (click)="goTo()"
             style="cursor: pointer" role="button" aria-hidden="true">
        <div class="card-body">
            <div class="d-flex justify-content-center align-items-center">
                <button class="mx-2" mat-stroked-button (click)="goTo()">
                    <i [class]="buttonIcon"></i> {{ buttonName }}
                </button>
            </div>
        </div>
    </div>
</div>
