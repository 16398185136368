import {Component, Output, OnInit, EventEmitter, Input,} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {WaterMeterService} from "../../../shared/services/water-meter.service";
import {WaterMeter} from "../../../shared/models/water-protocol/water-meter";


@Component({
    selector: 'app-water-meter-form',
    templateUrl: './water-meter-form.component.html',
    styleUrl: './water-meter-form.component.scss'
})
export class WaterMeterFormComponent implements OnInit {

    @Input() public waterMeter!: WaterMeter;
    @Input() public updateMode = false;

    @Output() public closeForm = new EventEmitter<void>();
    @Output() public update = new EventEmitter<void>();

    public waterMeters!: WaterMeter[];
    public waterMeterForm!: FormGroup;
    public isLoading = false;

    public meterNumberMinLength = 3;
    public meterNumberMaxLength = 60;
    public meterCounterNumberMinLength = 3;
    public meterCounterNumberMaxLength = 60;
    public radioOverlayNumberMinLength = 3;
    public radioOverlayNumberMaxLength = 60;

    constructor(protected formBuilder: FormBuilder,
                protected waterMeterService: WaterMeterService) {
    }

    ngOnInit(): void {
        this.buildWaterMeterForm();
        this.getWaterMeters();
    }

    public get type(): AbstractControl {
        return this.waterMeterForm.controls['type'];
    }

    public get number(): AbstractControl {
        return this.waterMeterForm.controls['number'];
    }

    public get counter(): AbstractControl {
        return this.waterMeterForm.controls['counter'];
    }

    public get radioOverlayNumber(): AbstractControl {
        return this.waterMeterForm.controls['radio_overlay_number'];
    }

    public closeFormEmit(): void {
        this.closeForm.emit();
    }

    public addWaterMeter(): void {
        if (this.updateMode) {
            this.update.emit();
        } else {
            this.waterMeterService.addWaterMeter(this.waterMeterForm.getRawValue());
            this.getWaterMeters();
            this.closeForm.emit();
        }
    }

    public getWaterMeters(): void {
        if (this.updateMode) {
            this.isLoading = true;
            setTimeout((): void => {
                this.setMaterMeter(this.waterMeter);
                this.isLoading = false;
            }, 500);
        } else {
            this.waterMeters = this.waterMeterService.meters;
        }
    }

    private setMaterMeter(waterMeter: WaterMeter): void {
        const {...formData} = waterMeter;
        this.waterMeterForm.patchValue(formData);
    }

    private buildWaterMeterForm(): void {
        this.waterMeterForm = this.formBuilder.group({
            type: [''],
            number: ['', {
                validators: [
                    Validators.required, Validators.minLength(this.meterNumberMinLength), Validators.maxLength(this.meterNumberMaxLength)]
            }],
            counter: ['', {
                validators: [
                    Validators.required, Validators.minLength(this.meterCounterNumberMinLength), Validators.maxLength(this.meterCounterNumberMaxLength)]
            }],
            radio_overlay_number: ['', {
                validators: [
                    Validators.required, Validators.minLength(this.radioOverlayNumberMinLength), Validators.maxLength(this.radioOverlayNumberMaxLength)]
            }],
        });
    }
}
