import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";


@Component({
    selector: 'app-dashboard-card',
    templateUrl: './dashboard-card.component.html',
    styleUrl: './dashboard-card.component.scss'
})
export class DashboardCardComponent implements OnInit {

    @Input() public imageName = '';
    @Input() public buttonLink = '';
    @Input() public buttonIcon = '';
    @Input() public buttonName = '';

    public imageLink = '../../../../assets/images/dashboard-card/';

    constructor(private router: Router) {
    }

    ngOnInit(): void {
        this.imageLink += this.imageName;
    }

    public goTo(): void {
        this.router.navigateByUrl(`/secure/${this.buttonLink}`).then();
    }
}
