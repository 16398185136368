import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

// modules
import {MaterialModule} from "../shared/material/material.module";
import {SharedModule} from "../shared/shared.module";

// guard
import {authGuard} from "../shared/guard/auth.guard";

// components
import {SidenavMenuComponent} from './components/sidenav-menu/sidenav-menu.component';
import {SidenavToolbarComponent} from './components/sidenav-toolbar/sidenav-toolbar.component';
import {ServiceOrderFormComponent} from './service-orders/service-order-form/service-order-form.component';
import {ServiceOrderSaveComponent} from './service-orders/service-order-save/service-order-save.component';
import {ServiceOrdersListComponent} from './service-orders/service-orders-list/service-orders-list.component';
import {
    ServiceOrderStatusColorComponent
} from './service-orders/components/service-order-status-color/service-order-status-color.component';
import {
    ServiceOrderMessageComponent
} from './service-orders/components/service-order-message/service-order-message.component';
import {
    ServiceOrderSaveButtonComponent
} from './service-orders/components/service-order-save-button/service-order-save-button.component';
import {
    ServiceOrderStatusEditComponent
} from './service-orders/components/service-order-status-edit/service-order-status-edit.component';
import {
    ServiceOrderStatusEditButtonComponent
} from './service-orders/components/service-order-status-edit-button/service-order-status-edit-button.component';
import {ChatMessageModalComponent} from './service-orders/components/chat-message-modal/chat-message-modal.component';
import {
    WorkingTimeSaveButtonComponent
} from './working-times/components/working-time-save-button/working-time-save-button.component';
import {WaterMeterFormComponent} from './water-protocol/water-meter-form/water-meter-form.component';
import {WaterMeterComponent} from './water-protocol/components/water-meter/water-meter.component';
import {DeleteModalComponent} from './components/delete-modal/delete-modal.component';
import {
    ServiceOrderImagesFormComponent
} from './service-order-images/service-order-images-form/service-order-images-form.component';
import {ImageViewModalComponent} from './components/image-view-modal/image-view-modal.component';
import {ImageCardComponent} from './service-order-images/image-card/image-card.component';
import {FormSaveButtonComponent} from './components/form-save-button/form-save-button.component';
import {UserFormComponent} from './users/user-form/user-form.component';
import {DashboardCardComponent} from './dashboard/dashboard-card/dashboard-card.component';

// secure pages
import {SecureComponent} from './secure.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {ServiceOrdersComponent} from './service-orders/service-orders.component';
import {ServiceOrderComponent} from './service-orders/service-order/service-order.component';
import {ServiceOrdersCurrentComponent} from './service-orders/service-orders-current/service-orders-current.component';
import {ServiceOrdersArchiveComponent} from './service-orders/service-orders-archive/service-orders-archive.component';
import {UsersComponent} from './users/users.component';
import {ProfileComponent} from './profile/profile.component';
import {UsersListComponent} from './users/users-list/users-list.component';
import {WorkingTimesComponent} from './working-times/working-times.component';
import {WorkingTimesListComponent} from './working-times/working-times-list/working-times-list.component';
import {WorkingTimeShowComponent} from './working-times/working-time-show/working-time-show.component';
import {WorkingTimeSaveComponent} from './working-times/working-time-save/working-time-save.component';
import {WorkingTimeFormComponent} from './working-times/working-time-form/working-time-form.component';
import {WorkingTimeUpdateComponent} from './working-times/working-time-update/working-time-update.component';
import {WaterProtocolComponent} from './water-protocol/water-protocol.component';
import {WaterProtocolSaveComponent} from './water-protocol/water-protocol-save/water-protocol-save.component';
import {WaterProtocolFormComponent} from './water-protocol/water-protocol-form/water-protocol-form.component';
import {ServiceOrderImagesComponent} from './service-order-images/service-order-images.component';
import {
    ServiceOrderImagesSaveComponent
} from './service-order-images/service-order-images-save/service-order-images-save.component';
import {
    ServiceOrderAddressEditComponent
} from './service-orders/service-order-address-edit/service-order-address-edit.component';
import {AddressFormComponent} from './service-orders/service-order-address-edit/address-form/address-form.component';
import {
    ServiceOrderCompletedModalComponent
} from './service-orders/components/service-order-completed-modal/service-order-completed-modal.component';
import {IntercomCodesComponent} from './intercom-codes/intercom-codes.component';
import {IntercomCodesListComponent} from './intercom-codes/intercom-codes-list/intercom-codes-list.component';
import {IntercomCodeFormComponent} from './intercom-codes/intercom-code-form/intercom-code-form.component';
import {IntercomCodeSaveComponent} from './intercom-codes/intercom-code-save/intercom-code-save.component';
import {IntercomCodeUpdateComponent} from './intercom-codes/intercom-code-update/intercom-code-update.component';
import {
    IntercomCodeShowModalComponent
} from './intercom-codes/intercom-code-show-modal/intercom-code-show-modal.component';
import {WaterMeterUpdateComponent} from './water-protocol/water-meter-update/water-meter-update.component';
import {UserShowComponent} from './users/user-show/user-show.component';


const routes: Routes = [

    // secure pages
    {
        path: '',
        component: SecureComponent,
        canActivate: [authGuard],
        children: [
            {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
            {path: 'dashboard', component: DashboardComponent, title: "Dashboard"},
            {path: 'service-order-add', component: ServiceOrderSaveComponent, title: "Service Order Add"},
            {path: 'service-order-add/:id', component: ServiceOrderSaveComponent, title: "Service Order Add"},
            {path: 'service-orders', component: ServiceOrdersCurrentComponent, title: "Service Current"},
            {path: 'service-orders-archive', component: ServiceOrdersArchiveComponent, title: "Service Archive"},
            {path: 'address-edit/:id', component: ServiceOrderAddressEditComponent, title: "Address Edit"},
            {path: 'service-order/:id', component: ServiceOrderComponent, title: "Service Order"},
            {path: 'service-order-images/:id', component: ServiceOrderImagesComponent, title: "Service Order Images"},
            {
                path: 'service-order-images-add/:id',
                component: ServiceOrderImagesSaveComponent,
                title: "Add Service Order Image(s)"
            },
            {path: 'water-protocol-add/:id', component: WaterProtocolSaveComponent, title: "Water Protocol Add"},
            {path: 'water-protocol/:id', component: WaterProtocolComponent, title: "Water Protocol"},
            {path: 'water-meter-update/:id', component: WaterMeterUpdateComponent, title: "Water Meter Update"},
            {path: 'intercom-code-add', component: IntercomCodeSaveComponent, title: "Intercom Code Add"},
            {path: 'intercom-code-update/:id', component: IntercomCodeUpdateComponent, title: "Intercom Code Update"},
            {path: 'intercom-codes', component: IntercomCodesComponent, title: "Intercom Codes"},
            {path: 'working-time-add', component: WorkingTimeSaveComponent, title: "Working Time Add"},
            {path: 'working-time-update/:id', component: WorkingTimeUpdateComponent, title: "Working Time Update"},
            {path: 'working-time-show/:id', component: WorkingTimeShowComponent, title: "Working Time Show"},
            {path: 'working-times', component: WorkingTimesComponent, title: "Working Times"},
            {path: 'user-show/:id', component: UserShowComponent, title: "User Profile Info"},
            {path: 'users', component: UsersComponent, title: "Users"},
            {path: 'profile', component: ProfileComponent, title: "Profile"}
        ]
    }
];

@NgModule({
    declarations: [
        SecureComponent,
        DashboardComponent,
        ProfileComponent,
        SidenavMenuComponent,
        SidenavToolbarComponent,
        ServiceOrdersComponent,
        ServiceOrderFormComponent,
        ServiceOrderSaveComponent,
        ServiceOrdersListComponent,
        ServiceOrderStatusColorComponent,
        ServiceOrderComponent,
        ServiceOrderMessageComponent,
        ServiceOrderSaveButtonComponent,
        ServiceOrderStatusEditComponent,
        ServiceOrderStatusEditButtonComponent,
        ChatMessageModalComponent,
        UsersComponent,
        UsersListComponent,
        WorkingTimesComponent,
        WorkingTimesListComponent,
        WorkingTimeSaveComponent,
        WorkingTimeFormComponent,
        WorkingTimeSaveButtonComponent,
        WorkingTimeUpdateComponent,
        WaterProtocolComponent,
        WaterProtocolSaveComponent,
        WaterProtocolFormComponent,
        WaterMeterFormComponent,
        WaterMeterComponent,
        DeleteModalComponent,
        ServiceOrderImagesComponent,
        ServiceOrderImagesSaveComponent,
        ServiceOrderImagesFormComponent,
        ImageViewModalComponent,
        ImageCardComponent,
        ServiceOrderAddressEditComponent,
        AddressFormComponent,
        ServiceOrdersCurrentComponent,
        ServiceOrdersArchiveComponent,
        ServiceOrderCompletedModalComponent,
        WorkingTimeShowComponent,
        IntercomCodesComponent,
        IntercomCodesListComponent,
        IntercomCodeFormComponent,
        IntercomCodeSaveComponent,
        IntercomCodeUpdateComponent,
        FormSaveButtonComponent,
        IntercomCodeShowModalComponent,
        WaterMeterUpdateComponent,
        UserShowComponent,
        UserFormComponent,
        DashboardCardComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        NgOptimizedImage,
        MaterialModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule
    ]
})
export class SecureModule {
}
