import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {WaterProtocolFormComponent} from "../water-protocol-form/water-protocol-form.component";
import {WaterMeterFormComponent} from "../water-meter-form/water-meter-form.component";
import {WaterMeterService} from "../../../shared/services/water-meter.service";
import {WaterProtocolService} from "../../../shared/services/water-protocol.service";
import {AuthService} from "../../../shared/services/auth.service";
import {SnackbarService} from "../../../shared/services/snackbar.service";
import {ApiMessage} from "../../../shared/models/api-message";

@Component({
    selector: 'app-water-protocol-save',
    templateUrl: './water-protocol-save.component.html',
    styleUrl: './water-protocol-save.component.scss'
})
export class WaterProtocolSaveComponent implements OnInit {

    @ViewChild('data') data!: WaterProtocolFormComponent;
    @ViewChild('meter') meter!: WaterMeterFormComponent;

    public serviceOrderID = 0;
    public addWaterMeterMode = false;

    constructor(private route: ActivatedRoute,
                private router: Router,
                protected waterMeterService: WaterMeterService,
                private authService: AuthService,
                private waterProtocolService: WaterProtocolService,
                private snackbarService: SnackbarService) {
    }

    ngOnInit(): void {
        this.serviceOrderID = Number(this.route.snapshot.paramMap.get('id'));
    }

    public closeForm(): void {
        this.router.navigateByUrl(`secure/service-order/${this.serviceOrderID}`).then();
        this.waterMeterService.clearWaterMeters();
    }

    public removeWaterMeter(index: number): void {
        this.waterMeterService.removeWaterMeter(index);
    }

    public addWaterProtocol(): void {
        const waterProtocolDataTMP = {
            service_order_id: this.serviceOrderID,
            ...this.data.waterProtocolForm.getRawValue(),
            water_meters: this.waterMeterService.meters
        }
        this.waterProtocolService.addWaterProtocol(this.authService.user.type, waterProtocolDataTMP).subscribe({
            next: (res: ApiMessage): void => {
                this.snackbarService.snackbarCustomComplete(res.message);
            },
            error: (e) => this.snackbarService.snackbarCustomError(e.error.message),
            complete: () => this.closeForm()
        })
    }

    public changeWaterMeterAddMode(): void {
        this.addWaterMeterMode = true;
    }

    public closeAddWaterMeter(): void {
        this.addWaterMeterMode = false;
    }
}
