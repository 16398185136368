import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatSelectChange} from "@angular/material/select";

// models
import {WorkingTime} from "../../../shared/models/working-time/working-time";

// date picker
import {FormControl} from "@angular/forms";
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import _moment from 'moment';

const moment = _moment;

export const DATE_FORMAT = {
    parse: {
        dateInput: ['YYYY-MM-DD']
    },
    display: {
        dateInput: 'DD/MM/Y',
        monthYearLabel: 'DD MM Y',
        dateA11yLabel: 'DD MM Y',
        monthYearA11yLabel: 'DD MM Y',
    },
};

@Component({
    selector: 'app-working-time-form',
    templateUrl: './working-time-form.component.html',
    styleUrl: './working-time-form.component.scss',
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        {provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT},
    ],
})
export class WorkingTimeFormComponent implements OnInit {

    @Input() public endMode = false;
    @Input() public workingTime!: WorkingTime;

    public date = new FormControl<_moment.Moment | null>(moment());
    public workingTimeForm!: FormGroup;
    public commentMinLength = 3;
    public commentMaxLength = 400;
    public hours: number[] = [];
    public isLoading = false;

    constructor(protected formBuilder: FormBuilder) {
    }

    ngOnInit(): void {
        this.generateHour();
        this.buildWorkingTimeForm();
        if (this.endMode) {
            this.isLoading = true;
            setTimeout((): void => {
                if (this.workingTime.end) {
                    this.setWorkingEndTime(this.workingTime);
                    this.isLoading = false;
                } else {
                    this.setWorkingStartTime(this.workingTime);
                    this.isLoading = false;
                }
            }, 500)
        } else {
            this.setCurrentDateTime();
        }
    }

    public get startDate(): AbstractControl {
        return this.workingTimeForm.controls['start_date'];
    }

    public get hour(): AbstractControl {
        return this.workingTimeForm.controls['hour'];
    }

    public get minute(): AbstractControl {
        return this.workingTimeForm.controls['minute'];
    }

    public get comment(): AbstractControl {
        return this.workingTimeForm.controls['comment'];
    }

    public setCurrentDateTime(): void {
        this.date.setValue(moment());
        this.startDate.setValue(moment()?.format('YYYY-MM-DD'));
    }

    public setStartDate(): void {
        this.startDate.setValue(this.date.getRawValue()?.format('YYYY-MM-DD'));
    }

    public generateHour(): void {
        for (let i = 0; i < 24; i++) {
            this.hours.push(i);
        }
    }

    public setHour($event: MatSelectChange): void {
        this.hour.setValue($event.value);
    }

    public setMinutes($event: MatSelectChange): void {
        this.minute.setValue($event.value);
    }

    private setWorkingStartTime(workingTime: WorkingTime): void {
        this.date.setValue(moment(workingTime.start));
        this.startDate.setValue(moment(workingTime.start)?.format('YYYY-MM-DD'));
    }

    private setWorkingEndTime(workingTime: WorkingTime): void {
        const workingTimeTemp = new Date(workingTime.end);
        this.startDate.setValue(moment(workingTime.end)?.format('YYYY-MM-DD'));
        this.hour.setValue(workingTimeTemp.getHours() - 1);
        this.minute.setValue(workingTimeTemp.getMinutes());
        this.date.setValue(moment(workingTime.end));
        if (workingTime.comment) {
            this.comment.setValue(workingTime.comment);
        }
    }

    private buildWorkingTimeForm(): void {
        this.workingTimeForm = this.formBuilder.group({
            start_date: ['', {validators: [Validators.required]}],
            hour: [0, {validators: [Validators.required]}],
            minute: [0, {validators: [Validators.required]}],
            comment: ['', {
                validators: [
                    Validators.minLength(this.commentMinLength), Validators.maxLength(this.commentMaxLength)]
            }],
        });
    }
}
