import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {WorkingTimeFormComponent} from "../working-time-form/working-time-form.component";

// services
import {WorkingTimeService} from "../../../shared/services/working-time.service";

// models
import {WorkingTime} from "../../../shared/models/working-time/working-time";
import {ApiMessage} from "../../../shared/models/api-message";
import {SnackbarService} from "../../../shared/services/snackbar.service";
import {AuthService} from "../../../shared/services/auth.service";


@Component({
    selector: 'app-working-time-update',
    templateUrl: './working-time-update.component.html',
    styleUrl: './working-time-update.component.scss'
})
export class WorkingTimeUpdateComponent implements OnInit {

    public isLoading = false;
    public workingTimeID = 0;
    public workingTime!: WorkingTime;

    @ViewChild('data') data!: WorkingTimeFormComponent;

    constructor(protected authService: AuthService,
                private workingTimesService: WorkingTimeService,
                private snackbarService: SnackbarService,
                private router: Router,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.workingTimeID = Number(this.route.snapshot.paramMap.get('id'));
        this.getWorkingTime(this.workingTimeID);
    }

    public updateWorkingTime(): void {
        const workingTimeForm = this.data.workingTimeForm.getRawValue();
        const workingTimeData = {
            end: workingTimeForm.start_date + ' ' + workingTimeForm.hour + ':' + workingTimeForm.minute,
            comment: workingTimeForm.comment
        };
        this.workingTimesService.updateWorkingTime(this.authService.user.type, this.workingTimeID, workingTimeData).subscribe({
            next: (res: ApiMessage): void => {
                this.snackbarService.snackbarCustomComplete(res.message);
            },
            error: (e) => this.snackbarService.snackbarCustomError(e.error.message),
            complete: () => this.closeForm()
        })
    }

    public closeForm(): void {
        this.router.navigateByUrl('/secure/working-times').then();
    }

    private getWorkingTime(id: number): void {
        this.isLoading = true;
        this.workingTimesService.getWorkingTime(this.authService.user.type, id).subscribe((workingTime: WorkingTime): void => {
            this.workingTime = workingTime;
            this.isLoading = false;
        })
    }
}
