<div class="py-2 px-2 py-md-5 px-md-5">
    <div class="row">
        <div class="col-md-6">
            <mat-card class="bg-white mat-elevation-z3">
                <div class="row">
                    <div class="col-md-6">
                        <mat-card-header>
                            <mat-card-title>
                                <mat-icon class="mx-1" color="primary">keyboard</mat-icon>
                                Intercom Code Add
                            </mat-card-title>
                            <mat-card-subtitle>Add a intercom code.</mat-card-subtitle>
                        </mat-card-header>
                    </div>
                </div>
                <mat-card-content>
                    <div class="pt-2">
                        <app-intercom-code-form [intercomCode]="intercomCode" [editMode]="true"
                                                #data></app-intercom-code-form>
                        <app-form-save-button [invalid]="data.intercomCodeForm.invalid"
                                              (closeForm)="closeForm()"
                                              (save)="addIntercomCode()"></app-form-save-button>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
