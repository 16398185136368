import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ServiceOrderAddresses} from "../../../../shared/models/service-orders/service-order-addresses";
import {ServiceOrderAddressesData} from "../../../../shared/models/service-orders/service-order-addresses-data";

@Component({
    selector: 'app-address-form',
    templateUrl: './address-form.component.html',
    styleUrl: './address-form.component.scss'
})
export class AddressFormComponent implements OnInit {

    @Input() public serviceOrderAddress!: ServiceOrderAddresses;

    public addressForm!: FormGroup;
    public isLoading = true;

    // validators min/max rules
    public nameMinLength = 3;
    public nameMaxLength = 60;
    public phoneNumberMinLength = 3;
    public phoneNumberMaxLength = 60;
    public streetNameMinLength = 3;
    public streetNameMaxLength = 60;
    public streetNumberMinLength = 1;
    public streetNumberMaxLength = 10;
    public apartmentNumberMaxLength = 10;
    public zipCodeMinLength = 3;
    public zipCodeMaxLength = 10;
    public cityNameMinLength = 3;
    public cityNameMaxLength = 60;
    public intercomCodeMinLength = 3;
    public intercomCodeMaxLength = 10;


    constructor(protected formBuilder: FormBuilder) {
    }

    ngOnInit(): void {
        this.buildAddressForm();
        setTimeout((): void => {
            this.setAddress(this.serviceOrderAddress);
            this.isLoading = false;
        }, 1000);
    }

    public get name(): AbstractControl {
        return this.addressForm.controls['name'];
    }

    public get phone_number(): AbstractControl {
        return this.addressForm.controls['phone_number'];
    }

    public get street_name(): AbstractControl {
        return this.addressForm.controls['street_name'];
    }

    public get street_number(): AbstractControl {
        return this.addressForm.controls['street_number'];
    }

    public get zip_code(): AbstractControl {
        return this.addressForm.controls['zip_code'];
    }

    public get city_name(): AbstractControl {
        return this.addressForm.controls['city_name'];
    }

    public get intercom_code(): AbstractControl {
        return this.addressForm.controls['intercom_code'];
    }

    private buildAddressForm(): void {
        this.addressForm = this.formBuilder.group({
            name: ['', {
                validators: [
                    Validators.required, Validators.minLength(this.nameMinLength), Validators.maxLength(this.nameMaxLength)]
            }],
            phone_number: ['', {
                validators: [
                    Validators.required, Validators.minLength(this.phoneNumberMinLength), Validators.maxLength(this.phoneNumberMaxLength)]
            }],
            street_name: ['', {
                validators: [
                    Validators.required, Validators.minLength(this.streetNameMinLength), Validators.maxLength(this.streetNameMaxLength)]
            }],
            street_number: ['', {
                validators: [
                    Validators.required, Validators.minLength(this.streetNumberMinLength), Validators.maxLength(this.streetNumberMaxLength)]
            }],
            apartment_number: ['', {
                validators: [Validators.maxLength(this.apartmentNumberMaxLength)]
            }],
            zip_code: ['', {
                validators: [
                    Validators.required, Validators.minLength(this.zipCodeMinLength), Validators.maxLength(this.zipCodeMaxLength)]
            }],
            city_name: ['', {
                validators: [
                    Validators.required, Validators.minLength(this.cityNameMinLength), Validators.maxLength(this.cityNameMaxLength)]
            }],
            intercom_code: ['', {
                validators: [
                    Validators.required, Validators.minLength(this.intercomCodeMinLength), Validators.maxLength(this.intercomCodeMaxLength)]
            }],
        });
    }

    private setAddress(serviceOrderAddress: ServiceOrderAddressesData): void {
        const {...formData} = serviceOrderAddress;
        this.addressForm.patchValue(formData);
    }
}
