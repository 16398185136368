import {Component} from '@angular/core';

@Component({
    selector: 'app-service-orders-current',
    templateUrl: './service-orders-current.component.html',
    styleUrl: './service-orders-current.component.scss'
})
export class ServiceOrdersCurrentComponent {

}
