import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
    selector: 'app-service-order-form',
    templateUrl: './service-order-form.component.html',
    styleUrl: './service-order-form.component.scss'
})
export class ServiceOrderFormComponent implements OnInit {


    public serviceOrderForm!: FormGroup;

    // validators min/max rules
    public descriptionMinLength = 3;
    public descriptionMaxLength = 400;
    public nameMinLength = 3;
    public nameMaxLength = 60;
    public phoneNumberMinLength = 3;
    public phoneNumberMaxLength = 60;
    public streetNameMinLength = 3;
    public streetNameMaxLength = 60;
    public streetNumberMinLength = 1;
    public streetNumberMaxLength = 10;
    public apartmentNumberMaxLength = 10;
    public zipCodeMinLength = 3;
    public zipCodeMaxLength = 10;
    public cityNameMinLength = 3;
    public cityNameMaxLength = 60;
    public intercomCodeMinLength = 3;
    public intercomCodeMaxLength = 100;

    constructor(protected formBuilder: FormBuilder) {
    }

    ngOnInit() {
        this.buildServiceOrderForm();
    }

    public get type(): AbstractControl {
        return this.serviceOrderForm.controls['type'];
    }

    public get description(): AbstractControl {
        return this.serviceOrderForm.controls['description'];
    }

    public get phone_number(): AbstractControl {
        return this.serviceOrderForm.controls['phone_number'];
    }

    public get name(): AbstractControl {
        return this.serviceOrderForm.controls['name'];
    }

    public get street_name(): AbstractControl {
        return this.serviceOrderForm.controls['street_name'];
    }

    public get street_number(): AbstractControl {
        return this.serviceOrderForm.controls['street_number'];
    }

    public get apartment_number(): AbstractControl {
        return this.serviceOrderForm.controls['apartment_number'];
    }

    public get zip_code(): AbstractControl {
        return this.serviceOrderForm.controls['zip_code'];
    }

    public get city_name(): AbstractControl {
        return this.serviceOrderForm.controls['city_name'];
    }

    public get intercom_code(): AbstractControl {
        return this.serviceOrderForm.controls['intercom_code'];
    }

    private buildServiceOrderForm(): void {
        this.serviceOrderForm = this.formBuilder.group({
            type: [''],
            description: ['', {
                validators: [
                    Validators.required, Validators.minLength(this.descriptionMinLength), Validators.maxLength(this.descriptionMaxLength)]
            }],
            name: ['', {
                validators: [
                    Validators.required, Validators.minLength(this.nameMinLength), Validators.maxLength(this.nameMaxLength)]
            }],
            phone_number: ['', {
                validators: [
                    Validators.required, Validators.minLength(this.phoneNumberMinLength), Validators.maxLength(this.phoneNumberMaxLength)]
            }],
            street_name: ['', {
                validators: [
                    Validators.required, Validators.minLength(this.streetNameMinLength), Validators.maxLength(this.streetNameMaxLength)]
            }],
            street_number: ['', {
                validators: [
                    Validators.required, Validators.minLength(this.streetNumberMinLength), Validators.maxLength(this.streetNumberMaxLength)]
            }],
            apartment_number: ['', {
                validators: [Validators.maxLength(this.apartmentNumberMaxLength)]
            }],
            zip_code: ['', {
                validators: [
                    Validators.required, Validators.minLength(this.zipCodeMinLength), Validators.maxLength(this.zipCodeMaxLength)]
            }],
            city_name: ['', {
                validators: [
                    Validators.required, Validators.minLength(this.cityNameMinLength), Validators.maxLength(this.cityNameMaxLength)]
            }],
            intercom_code: ['', {
                validators: [
                    Validators.required, Validators.minLength(this.intercomCodeMinLength), Validators.maxLength(this.intercomCodeMaxLength)]
            }],
            confirm: [false, Validators.pattern('true')]
        });
    }
}
