import {Component, Inject, OnInit} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {ImagesService} from "../../../shared/services/images.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ServiceOrderImage} from "../../../shared/models/service-orders/service-order-image";


@Component({
    selector: 'app-image-view-modal',
    templateUrl: './image-view-modal.component.html',
    styleUrl: './image-view-modal.component.scss'
})
export class ImageViewModalComponent implements OnInit {

    public imageURL = '';
    public serviceOrderID = 0;
    private image!: ServiceOrderImage;
    private imageID = 0;

    constructor(private imagesService: ImagesService,
                private dialogRef: MatDialogRef<ImageViewModalComponent>,
                @Inject(MAT_DIALOG_DATA)
                    data: {
                    serviceOrderID: number,
                    imageID: number
                }) {
        this.serviceOrderID = data.serviceOrderID;
        this.imageID = data.imageID;
    }

    ngOnInit(): void {
        this.getImage();
    }

    private getImage(): void {
        this.imagesService.getImage(this.imageID).subscribe((image: ServiceOrderImage): void => {
            this.image = image;
            this.setProductImageUrl();
        })
    }

    private setProductImageUrl(): void {
        this.imageURL = '';
        this.imageURL = `${environment.apiPublic}/images/service_order/${this.serviceOrderID}/${this.image.image_name}`;
    }
}
