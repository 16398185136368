import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

// models
import {WorkingTimes} from "../models/working-time/working-times";
import {ApiMessage} from "../models/api-message";
import {WorkingTimeData} from "../models/working-time/working-time-data";
import {WorkingTime} from "../models/working-time/working-time";


@Injectable({
    providedIn: 'root'
})
export class WorkingTimeService {

    constructor(protected http: HttpClient) {
    }

    // get all working times form API - admin/member
    public index(userType: string, user_id?: number, page?: number, perPage?: number, sort?: string, direction?: string,
                 date_from?: string, date_to?: string): Observable<WorkingTimes> {

        let url = this.getURLbyUserType(userType) + `?user_id=${user_id}`;
        if (perPage) url += `&page=${page}&perPage=${perPage}&sort=${sort}&direction=${direction}`;
        if (date_from) url += `&date_from=${date_from}&date_to=${date_to}`;

        return this.http.get<WorkingTimes>(url);
    }

    // add/create working time in to API - admin/member
    createWorkingTime(userType: string, data: WorkingTimeData): Observable<ApiMessage> {
        const url = this.getURLbyUserType(userType);
        return this.http.post<ApiMessage>(url, data);
    }

    // get one working time form API - admin/member
    public getWorkingTime(userType: string, id: number): Observable<WorkingTime> {
        const url = this.getURLbyUserType(userType);
        return this.http.get<WorkingTime>(`${url}/${id}`);
    }

    // update one working time in API - admin/member
    public updateWorkingTime(userType: string, id: number, data: Partial<WorkingTimeData>): Observable<ApiMessage> {
        const url = this.getURLbyUserType(userType);
        return this.http.put<ApiMessage>(`${url}/${id}`, data);
    }

    // delete one working time - admin/member
    delete(id: number, userType?: string,): Observable<ApiMessage> {
        let url = '';
        if (userType) {
            url = this.getURLbyUserType(userType);
        }
        return this.http.delete<ApiMessage>(`${url}/${id}`);
    }

    // get API URL by a logged admin/member
    private getURLbyUserType(userType: string): string {
        let url = '';
        switch (userType) {
            case 'admin':
                url = `${environment.api}/working-time-admin`;
                break;
            case 'member':
                url = `${environment.api}/working-time-member`;
                break;
        }
        return url;
    }
}
