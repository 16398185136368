import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {HttpEventType} from "@angular/common/http";
import {catchError, EMPTY, Subject, takeUntil} from "rxjs";

// service
import {ImagesService} from "../../../shared/services/images.service";
import {SnackbarService} from "../../../shared/services/snackbar.service";
import {ServiceOrderImageData} from "../../../shared/models/service-orders/service-order-image-data";
import {AuthService} from "../../../shared/services/auth.service";


@Component({
    selector: 'app-service-order-images-save',
    templateUrl: './service-order-images-save.component.html',
    styleUrl: './service-order-images-save.component.scss'
})
export class ServiceOrderImagesSaveComponent implements OnInit {

    public isLoading = false;
    public serviceOrderID = 0;
    public progress = '';
    public resultClass = '';
    public productID = 0;
    public update = false;
    private cancel$: Subject<void> = new Subject<void>();
    public imageData: FormData = new FormData();

    constructor(private route: ActivatedRoute,
                private router: Router,
                protected authService: AuthService,
                private imagesService: ImagesService,
                private snackbarService: SnackbarService) {
    }

    ngOnInit(): void {
        this.serviceOrderID = Number(this.route.snapshot.paramMap.get('id'));
    }

    public closeForm(): void {
        this.router.navigateByUrl(`secure/service-order/${this.serviceOrderID}`).then();
    }

    public fileToUpload($event: ServiceOrderImageData[]): void {
        for (let i = 0; i < $event.length; i++) {
            this.imageData.append(`images[]`, $event[i].file);
        }
    }

    public imageDataReset(): void {
        if (this.imageData.has('images[]')) {
            this.imageData.delete(`images[]`);
        }
    }

    public saveImages(): void {
        this.uploadFile(this.imageData, this.serviceOrderID.toString());
    }

    public uploadFile(imageForm: FormData, serviceOrderID: string): void {
        this.isLoading = true;
        imageForm.append('service_order_id', serviceOrderID);
        const userType = this.authService.user.type;
        this.imagesService.uploadImage(userType, imageForm).pipe(
            catchError((e) => {
                this.snackbarService.snackbarCustomError(e.error.message);
                this.resultClass = 'bg-danger';
                return EMPTY;
            }),
            takeUntil(this.cancel$))
            .subscribe((httpEvent): void => {
                if (httpEvent.type === HttpEventType.UploadProgress) {
                    this.resultClass = '';
                    if (httpEvent.total) {
                        this.progress = `${Math.round(
                            100 * (httpEvent.loaded / httpEvent.total)
                        )}%`;
                    }
                }
                if (httpEvent.type === HttpEventType.Response) {
                    this.isLoading = false;
                    this.resultClass = 'bg-success';
                    this.addProductCorrectInfo();
                }
            });
    }

    public isLoadingEvent($event: boolean): void {
        this.isLoading = $event;
    }

    private addProductCorrectInfo(): void {
        this.snackbarService.snackbarComplete('add a image.');
        this.router.navigateByUrl(`secure/service-order/${this.serviceOrderID}`).then();
    }
}
