import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";


@Component({
    selector: 'app-service-order-completed-modal',
    templateUrl: './service-order-completed-modal.component.html',
    styleUrl: './service-order-completed-modal.component.scss'
})
export class ServiceOrderCompletedModalComponent {

    public serviceOrderID = 0;
    public checked = false;

    constructor(private dialogRef: MatDialogRef<ServiceOrderCompletedModalComponent>,
                @Inject(MAT_DIALOG_DATA)
                data: {
                    serviceOrderID: number
                }) {
        this.serviceOrderID = data.serviceOrderID;
    }

    public completed(): void {
        this.dialogRef.close();
    }
}
