import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

// services
import {AuthService} from "../../../shared/services/auth.service";
import {ServiceOrdersService} from "../../../shared/services/service-orders.service";
import {SnackbarService} from "../../../shared/services/snackbar.service";

// models
import {ServiceOrder} from "../../../shared/models/service-orders/service-order";
import {User} from "../../../shared/models/user/user";
import {MatDialog} from "@angular/material/dialog";
import {ChatMessageModalComponent} from "../components/chat-message-modal/chat-message-modal.component";
import {ApiMessage} from "../../../shared/models/api-message";
import {ChatMessageService} from "../../../shared/services/chat-message.service";
import {ServiceOrderMessageData} from "../../../shared/models/service-orders/service-order-message-data";
import {ServiceOrderMessage} from "../../../shared/models/service-orders/service-order-message";

@Component({
    selector: 'app-service-order',
    templateUrl: './service-order.component.html',
    styleUrl: './service-order.component.scss'
})
export class ServiceOrderComponent implements OnInit {

    public isLoading = false;
    public progressBar = false;
    public serviceOrderID = 0;
    public serviceOrder!: ServiceOrder;
    public serviceOrderReporter!: User;
    public reporterDeleteMode = false;
    public employeeDeleteMode = false;
    public closeLink = '/secure/service-orders';

    constructor(protected authService: AuthService,
                private ordersService: ServiceOrdersService,
                private chatMessageService: ChatMessageService,
                private route: ActivatedRoute,
                private router: Router,
                private snackbarService: SnackbarService,
                private dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.serviceOrderID = Number(this.route.snapshot.paramMap.get('id'));
        this.getServiceOrder(this.serviceOrderID, false);
    }

    public updateOrder(updateOrderData: Partial<ServiceOrder>): void {
        const userType = this.authService.user.type;
        if (this.serviceOrder.status !== updateOrderData.status) {
            this.ordersService.update(userType, this.serviceOrderID, updateOrderData).subscribe({
                next: (res: ApiMessage): void => {
                    this.progressBar = true;
                    this.snackbarService.snackbarCustomComplete(res.message);
                },
                error: (e) => this.snackbarService.snackbarCustomError(e.error.message),
                complete: () => {
                    if (this.serviceOrder.status === 'ready') {
                        this.router.navigateByUrl(`secure/service-orders`).then();
                    } else this.getServiceOrder(this.serviceOrderID, true)
                }
            })
        }
    }

    public editAddress(addressID: number): void {
        this.router.navigateByUrl(`secure/address-edit/${addressID}`).then();
    }

    public showImages(): void {
        this.router.navigateByUrl(`secure/service-order-images/${this.serviceOrderID}`).then();
    }

    public addImages(): void {
        this.router.navigateByUrl(`secure/service-order-images-add/${this.serviceOrderID}`).then();
    }

    public showWaterProtocol(): void {
        this.router.navigateByUrl(`secure/water-protocol/${this.serviceOrder.water_protocol[0].id}`).then();
    }

    public addWaterProtocol(): void {
        this.router.navigateByUrl(`secure/water-protocol-add/${this.serviceOrderID}`).then();
    }

    public addMessage(): void {
        this.dialog.open(ChatMessageModalComponent, {
            maxWidth: '100vw',
            maxHeight: '100vh',
            width: '650px',
            height: '30vh',
            data: {
                serviceOrderID: this.serviceOrderID
            }
        }).afterClosed().subscribe(
            (): void => {
                this.getServiceOrder(this.serviceOrderID, true);
            }
        );
    }

    public messageRead(messageID: number): void {

        const readData: Partial<ServiceOrderMessageData> = {}

        if (this.authService.user.type === 'admin') readData.admin_read = 1;
        if (this.authService.user.type === 'member') readData.member_read = 1;
        if (this.authService.user.type === 'user') readData.user_read = 1;

        this.chatMessageService.update(messageID, readData).subscribe({
            next: (res: ApiMessage): void => {
                this.snackbarService.snackbarCustomComplete(res.message);
            },
            error: (e) => this.snackbarService.snackbarCustomError(e.error.message),
            complete: () => this.getMessages(this.serviceOrderID)
        })
    }

    public getMessages(id: number): void {
        this.progressBar = true;
        this.chatMessageService.getMessages(id).subscribe((messages: ServiceOrderMessage[]): void => {
            this.serviceOrder.messages = messages;
            this.progressBar = false;
        })
    }

    public switchReporterDeleteMode(): void {
        this.employeeDeleteMode = false;
        this.reporterDeleteMode = !this.reporterDeleteMode;
    }

    public switchEmployeeDeleteMode(): void {
        this.reporterDeleteMode = false;
        this.employeeDeleteMode = !this.employeeDeleteMode;
    }

    private getServiceOrder(id: number, progressBar: boolean): void {
        if (progressBar) {
            this.progressBar = true;
        } else this.isLoading = true;
        const userType = this.authService.user.type;
        this.ordersService.getServiceOrder(userType, id).subscribe((serviceOrder: ServiceOrder): void => {
            this.serviceOrder = serviceOrder;
            if (this.serviceOrder.status === 'completed') this.closeLink = '/secure/service-orders-archive';
            if (this.authService.user.type !== 'user') {
                this.getUserData(serviceOrder.user_id);
            }
            if (progressBar) {
                this.progressBar = false;
            } else this.isLoading = false;
        })
    }

    private getUserData(id: number): void {
        this.authService.getUserData(id).subscribe((user: User): void => {
            this.serviceOrderReporter = user;
        })
    }
}
