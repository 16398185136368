import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

// models
import {IntercomCode} from "../models/intercom-codes/intercom-code";
import {IntercomCodes} from "../models/intercom-codes/intercom-codes";
import {ApiMessage} from "../models/api-message";


@Injectable({
    providedIn: 'root'
})
export class IntercomCodesService {

    constructor(protected http: HttpClient) {
    }

    // get all intercom codes form API - admin/member
    public index(userType: string, page?: number, perPage?: number, sort?: string, direction?: string, search?: string): Observable<IntercomCodes> {

        let url = this.getURLbyUserType(userType);
        if (perPage) url += `?page=${page}&perPage=${perPage}&sort=${sort}&direction=${direction}&search=${search}`;

        return this.http.get<IntercomCodes>(url);
    }

    // add/create intercom code in to API - admin/member
    createIntercomCode(userType: string, data: IntercomCode): Observable<ApiMessage> {
        const url = this.getURLbyUserType(userType);
        return this.http.post<ApiMessage>(url, data);
    }

    // get one intercom code form API - admin/member
    public getIntercomCode(userType: string, id: number): Observable<IntercomCode> {
        const url = this.getURLbyUserType(userType);
        return this.http.get<IntercomCode>(`${url}/${id}`);
    }

    // update one intercom code in API - admin/member
    public updateIntercomCode(userType: string, id: number, data: Partial<IntercomCode>): Observable<ApiMessage> {
        const url = this.getURLbyUserType(userType);
        return this.http.put<ApiMessage>(`${url}/${id}`, data);
    }

    // delete intercom code time - admin/member
    delete(id: number, userType?: string,): Observable<ApiMessage> {
        let url = '';
        if (userType) {
            url = this.getURLbyUserType(userType);
        }
        return this.http.delete<ApiMessage>(`${url}/${id}`);
    }

    // get API URL by a logged admin/member
    private getURLbyUserType(userType: string): string {
        let url = '';
        switch (userType) {
            case 'admin':
                url = `${environment.api}/intercom-code-admin`;
                break;
            case 'member':
                url = `${environment.api}/intercom-code-member`;
                break;
        }
        return url;
    }
}
