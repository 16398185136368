import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ServiceOrderMessage} from "../../../../shared/models/service-orders/service-order-message";
import {DeleteModalComponent} from "../../../components/delete-modal/delete-modal.component";
import {MatDialog} from "@angular/material/dialog";

// models
import {User} from "../../../../shared/models/user/user";

@Component({
    selector: 'app-service-order-message',
    templateUrl: './service-order-message.component.html',
    styleUrl: './service-order-message.component.scss'
})
export class ServiceOrderMessageComponent {

    @Input() public message!: ServiceOrderMessage;
    @Input() public user!: User;
    @Input() public deleteMode = false;

    @Output() public read = new EventEmitter<number>();
    @Output() public reload = new EventEmitter<number>();

    constructor(private dialog: MatDialog) {
    }

    public readEmit(id: number): void {
        this.read.emit(id);
    }

    public checkLoginUserRead(): boolean {
        if (this.user.type === 'admin') {
            if (this.message.admin_read === 0) {
                return true
            }
        }
        if (this.user.type === 'member') {
            if (this.message.member_read === 0) {
                return true
            }
        }
        if (this.user.type === 'user') {
            if (this.message.user_read === 0) {
                return true
            }
        }
        return false
    }

    public removeMessage(messageID: number): void {
        this.dialog.open(DeleteModalComponent, {
            data: {
                modalService: 'ChatMessage',
                modalName: 'message',
                id: messageID
            }
        }).afterClosed().subscribe(
            (res): void => {
                if (!res) {
                    this.reload.emit(this.message.service_order_id);
                }
            }
        );
    }
}
