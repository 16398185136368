import {Component, EventEmitter, Input, Output} from '@angular/core';
import {WaterMeter} from "../../../../shared/models/water-protocol/water-meter";

@Component({
    selector: 'app-water-meter',
    templateUrl: './water-meter.component.html',
    styleUrl: './water-meter.component.scss'
})
export class WaterMeterComponent {

    @Input() public waterMeters!: WaterMeter[];
    @Input() public deleteMode = false;
    @Input() public editMode = false;

    @Output() public delete = new EventEmitter<number>();
    @Output() public edit = new EventEmitter<number>();

    public deleteEmit(index: number): void {
        this.delete.emit(index);
    }

    public editEmit(id: number): void {
        this.edit.emit(id);
    }
}
