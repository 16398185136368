import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {IntercomCode} from "../../../shared/models/intercom-codes/intercom-code";
import {IntercomCodesService} from "../../../shared/services/intercom-codes.service";
import {AuthService} from "../../../shared/services/auth.service";

@Component({
    selector: 'app-intercom-code-show-modal',
    templateUrl: './intercom-code-show-modal.component.html',
    styleUrl: './intercom-code-show-modal.component.scss'
})
export class IntercomCodeShowModalComponent implements OnInit {

    public intercomCodeID = 0;
    public intercomCode!: IntercomCode;

    public isLoading = false;

    constructor(protected authService: AuthService,
                private intercomCodesService: IntercomCodesService,
                private dialogRef: MatDialogRef<IntercomCodeShowModalComponent>,
                @Inject(MAT_DIALOG_DATA)
                data: {
                    intercomCodeID: number
                }) {
        this.intercomCodeID = data.intercomCodeID;
    }

    ngOnInit(): void {
        this.getIntercomCode(this.intercomCodeID);
    }

    private getIntercomCode(id: number): void {
        this.isLoading = true;
        this.intercomCodesService.getIntercomCode(this.authService.user.type, id).subscribe((intercomCode: IntercomCode): void => {
            this.intercomCode = intercomCode;
            this.isLoading = false;
        })
    }
}
