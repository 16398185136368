import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {WaterMeterFormComponent} from "../water-meter-form/water-meter-form.component";

// service
import {AuthService} from "../../../shared/services/auth.service";
import {SnackbarService} from "../../../shared/services/snackbar.service";
import {WaterMeterService} from "../../../shared/services/water-meter.service";

// models
import {WaterMeter} from "../../../shared/models/water-protocol/water-meter";
import {ApiMessage} from "../../../shared/models/api-message";


@Component({
    selector: 'app-water-meter-update',
    templateUrl: './water-meter-update.component.html',
    styleUrl: './water-meter-update.component.scss'
})
export class WaterMeterUpdateComponent implements OnInit {

    @ViewChild('meter') meter!: WaterMeterFormComponent;

    public isLoading = false;
    public waterMeterID = 0;
    public waterMeter!: WaterMeter;

    constructor(protected authService: AuthService,
                private waterMeterService: WaterMeterService,
                private snackbarService: SnackbarService,
                private router: Router,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.waterMeterID = Number(this.route.snapshot.paramMap.get('id'));
        this.getWaterMeter(this.waterMeterID);
    }

    public updateWaterMeter(): void {
        this.waterMeterService.updateWaterMeter(this.authService.user.type, this.waterMeterID, this.meter.waterMeterForm.getRawValue()).subscribe({
            next: (res: ApiMessage): void => {
                this.snackbarService.snackbarCustomComplete(res.message);
            },
            error: (e) => this.snackbarService.snackbarCustomError(e.error.message),
            complete: () => this.closeForm()
        })
    }

    public closeForm(): void {
        this.router.navigateByUrl(`secure/water-protocol/${this.waterMeter.water_protocol_id}`).then();
        this.waterMeterService.clearWaterMeters();
    }

    private getWaterMeter(id: number): void {
        this.isLoading = true;
        this.waterMeterService.getWaterMeter(this.authService.user.type, id).subscribe((waterMeter: WaterMeter): void => {
            this.waterMeter = waterMeter;
            this.isLoading = false;
        })
    }
}
