<div class="py-2 px-2 py-md-5 px-md-5">
    <div class="row">
        <div *ngIf="isLoading; else content">
            <div class="d-flex justify-content-center mt-5">
                <mat-spinner></mat-spinner>
            </div>
        </div>
        <ng-template #content>
            <div class="col-md-6">
                <mat-card class="bg-white mat-elevation-z3">
                    <a class="d-flex flex-row-reverse link-secondary mx-2 mt-2" (click)="closeForm()"
                       style="cursor: pointer" (keydown.backspace)="closeForm()" role="button" aria-hidden="true">
                        <mat-icon aria-hidden="false" matTooltip="Close" matTooltipPosition="below">close</mat-icon>
                    </a>
                    <div class="col-9 header">
                        <mat-card-header>
                            <mat-card-title><i class="fa-solid fa-location-dot text-primary-color"></i>
                                Service Order Address Edit
                            </mat-card-title>
                            <mat-card-subtitle>Change or correct service order address .</mat-card-subtitle>
                        </mat-card-header>
                    </div>
                    <mat-card-content>
                        <ng-container *ngIf="serviceOrderAddress">
                            <div class="pt-4">
                                <h4>Service Order: <b class="text-primary-color">
                                    {{ serviceOrderAddress.service_order_id }}</b></h4>
                            </div>
                        </ng-container>
                        <app-address-form [serviceOrderAddress]="serviceOrderAddress" #data></app-address-form>
                        <app-save-button [invalid]="data.addressForm.invalid" (closeForm)="closeForm()"
                                         (save)="updateAddress()"></app-save-button>
                    </mat-card-content>
                </mat-card>
            </div>
        </ng-template>
    </div>
</div>
