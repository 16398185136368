import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

// services
import {WaterProtocolService} from "../../../shared/services/water-protocol.service";
import {ImagesService} from "../../../shared/services/images.service";
import {WorkingTimeService} from "../../../shared/services/working-time.service";
import {SnackbarService} from "../../../shared/services/snackbar.service";
import {ChatMessageService} from "../../../shared/services/chat-message.service";
import {AuthService} from "../../../shared/services/auth.service";
import {IntercomCodesService} from "../../../shared/services/intercom-codes.service";

@Component({
    selector: 'app-delete-modal',
    templateUrl: './delete-modal.component.html',
    styleUrl: './delete-modal.component.scss'
})
export class DeleteModalComponent {

    public modalService = '';
    public modalName = '';
    public id = 0;
    public checked = false;

    public service!: WaterProtocolService | ImagesService | WorkingTimeService | ChatMessageService | IntercomCodesService;

    constructor(private waterProtocolService: WaterProtocolService,
                private imagesService: ImagesService,
                private workingTimesService: WorkingTimeService,
                private chatMessageService: ChatMessageService,
                private intercomCodesService: IntercomCodesService,
                private snackbarService: SnackbarService,
                protected authService: AuthService,
                private dialogRef: MatDialogRef<DeleteModalComponent>,
                @Inject(MAT_DIALOG_DATA)
                data: {
                    modalService: string,
                    modalName: string
                    id: number
                }) {
        this.modalService = data.modalService;
        this.modalName = data.modalName;
        this.id = data.id;

        switch (this.modalService) {
            case 'WaterProtocol':
                this.service = this.waterProtocolService;
                break;
            case 'image':
                this.service = this.imagesService;
                break;
            case 'IntercomCode':
                this.service = this.intercomCodesService;
                break;
            case 'WorkingTime':
                this.service = this.workingTimesService;
                break;
            case 'ChatMessage':
                this.service = this.chatMessageService;
                break;
        }
    }

    public delete(id: number): void {
        if (this.modalService === 'WorkingTime' || this.modalService === 'IntercomCode') {
            this.service.delete(id, this.authService.user.type).subscribe({
                next: () => this.dialogRef.close(),
                error: () => this.snackbarService.snackbarError(),
                complete: () => this.snackbarService.snackbarComplete(`deleted the ${this.modalName}.`)
            });
        } else {
            this.service.delete(id).subscribe({
                next: () => this.dialogRef.close(),
                error: () => this.snackbarService.snackbarError(),
                complete: () => this.snackbarService.snackbarComplete(`deleted the ${this.modalName}.`)
            });
        }
    }
}
