import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

// service
import {AuthService} from "../../../shared/services/auth.service";
import {SnackbarService} from "../../../shared/services/snackbar.service";

// models
import {User} from "../../../shared/models/user/user";
import {ApiMessage} from "../../../shared/models/api-message";
import {UserFormComponent} from "../user-form/user-form.component";


@Component({
    selector: 'app-user-show',
    templateUrl: './user-show.component.html',
    styleUrl: './user-show.component.scss'
})
export class UserShowComponent implements OnInit {

    @ViewChild('data') data!: UserFormComponent;

    public isLoading = false;
    public isUpdating = false;
    public editTypeMode = false;
    public editNameMode = false;
    public userID = 0;
    public user!: User;
    public userType = '';

    constructor(protected authService: AuthService,
                private router: Router,
                private snackbarService: SnackbarService,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.userID = Number(this.route.snapshot.paramMap.get('id'));
        this.getUserData(this.userID);
    }

    public back(): void {
        this.router.navigateByUrl(`secure/users`).then();
    }

    public switchEditNameMode(): void {
        this.editNameMode = !this.editNameMode;
    }

    public switchEditTypeMode(): void {
        this.editTypeMode = !this.editTypeMode;
    }

    public updateUserType(): void {
        this.isUpdating = true;
        let data = {};
        if (this.editNameMode) {
            data = this.data.userNameForm.getRawValue();
        }
        if (this.editTypeMode) {
            data = {
                type: this.userType
            };
        }
        this.authService.updateUserData(this.userID, data).subscribe({
            next: (res: ApiMessage): void => {
                this.snackbarService.snackbarCustomComplete(res.message);
                this.authService.getUserData(this.userID).subscribe((user: User): void => {
                    this.user = user;
                    this.userType = this.user.type;
                    this.isUpdating = false;
                })
            },
            error: (e) => this.snackbarService.snackbarCustomError(e.error.message),
            complete: () => {
                this.editNameMode = false;
                this.editTypeMode = false;
            }
        })
    }

    private getUserData(id: number): void {
        this.isLoading = true;
        this.authService.getUserData(id).subscribe((user: User): void => {
            this.user = user;
            this.userType = this.user.type;
            this.isLoading = false;
            this.isUpdating = false;
        })
    }
}
