<div class="py-2 px-2 py-md-5 px-md-5">
    <app-service-order-images-form [productID]="productID" [progress]="progress"
                                   [resultClass]="resultClass"
                                   (cancel)="closeForm()"
                                   (fileToUpload)="fileToUpload($event)"
                                   (isLoading)="isLoadingEvent($event)"
                                   (imageDataReset)="imageDataReset()">
    </app-service-order-images-form>
    <div class="col-md-6" *ngIf="!isLoading">
        <app-save-button [invalid]="!imageData.has('images[]')"
                         [addMode]="true"
                         (closeForm)="closeForm()"
                         (save)="saveImages()"></app-save-button>
    </div>
</div>
