<div class="py-4">
    <div *ngIf="isLoading; else content">
        <div class="d-flex justify-content-center py-2">
            <mat-spinner></mat-spinner>
        </div>
    </div>
    <ng-template #content>
        <form [formGroup]="intercomCodeForm">
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>Address:</mat-label>
                <input matInput formControlName="address" name="address" type="text" placeholder="Address"
                       #displayAddress>
                <mat-hint align="end">
                    {{ displayAddress.value.length }} / {{ addressMaxLength }}
                </mat-hint>
                <mat-error *ngIf="address.errors?.['required']">
                    Please enter a address or nick name.
                </mat-error>
                <mat-error *ngIf="address.value.length < addressMinLength && !address.errors?.['required']">
                    A minimum length of address is {{ addressMinLength }} characters.
                </mat-error>
                <mat-error *ngIf="address.value.length > addressMaxLength">
                    A maximum length of address is {{ addressMaxLength }} characters.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width py-2" appearance="outline">
                <mat-label>Number:</mat-label>
                <input matInput formControlName="number" name="number" type="text" placeholder="Number"
                       #displayNumber>
                <mat-hint align="end">
                    {{ displayNumber.value.length }} / {{ numberMaxLength }}
                </mat-hint>
                <mat-error *ngIf="number.errors?.['required']">
                    Please enter a number or nick name.
                </mat-error>
                <mat-error *ngIf="number.value.length < numberMinLength && !number.errors?.['required']">
                    A minimum length of number is {{ numberMinLength }} characters.
                </mat-error>
                <mat-error *ngIf="number.value.length > numberMaxLength">
                    A maximum length of number is {{ numberMaxLength }} characters.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>Code:</mat-label>
                <input matInput formControlName="code" name="code" type="text" placeholder="Code"
                       #displayCode>
                <mat-hint align="end">
                    {{ displayCode.value.length }} / {{ codeMaxLength }}
                </mat-hint>
                <mat-error *ngIf="code.errors?.['required']">
                    Please enter a code or nick name.
                </mat-error>
                <mat-error *ngIf="code.value.length < codeMinLength && !code.errors?.['required']">
                    A minimum length of code is {{ codeMinLength }} characters.
                </mat-error>
                <mat-error *ngIf="code.value.length > codeMaxLength">
                    A maximum length of code is {{ codeMaxLength }} characters.
                </mat-error>
            </mat-form-field>
        </form>
    </ng-template>
</div>
