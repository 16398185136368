<mat-toolbar color="primary">
    <mat-icon>keyboard</mat-icon>
    <span class="mx-2">Intercom Code Info</span>
    <span class="toolbar-spacer"></span>
    <button class="text-white" mat-icon-button matTooltip="Close" matTooltipPosition="below" [mat-dialog-close]="true"
            aria-hidden="false">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>
<mat-dialog-content>
    <div *ngIf="isLoading; else content">
        <div class="d-flex justify-content-center mt-5">
            <mat-spinner></mat-spinner>
        </div>
    </div>
    <ng-template #content>
        <div class="py-2">
            <h4><i class="fa-solid fa-location-pin text-primary-color"></i> <b class="text-primary-color">
                Address:</b> {{ intercomCode.address }}
                {{ intercomCode.number }}</h4>
            <div class="pt-2">
                <h4><i class="fa-solid fa-keyboard text-primary-color"></i> <b class="text-primary-color">
                    Code:</b> {{ intercomCode.code }}
                    {{ intercomCode.number }}</h4>
            </div>
        </div>
    </ng-template>
</mat-dialog-content>
<div class="d-flex justify-content-end pb-4">
    <button class="full-width mx-2" mat-raised-button [mat-dialog-close]="true"
            matTooltip="Cancel - close modal" matTooltipPosition="below" aria-hidden="false">
        <i class="fa-solid fa-xmark"></i> Close
    </button>
</div>
