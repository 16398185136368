<mat-card class="bg-white mat-elevation-z3">
    <mat-card-header>
        <mat-card-title>
            <mat-icon class="mx-1" color="primary">history</mat-icon>
            Working Times
        </mat-card-title>
        <mat-card-subtitle>
            Check & edit working times.
        </mat-card-subtitle>
        <div class="mx-3" *ngIf="userID">
            <button mat-fab color="warn" matTooltip="Add working time" (click)="addWorkingTime()"
                    matTooltipPosition="right" aria-label="Add working time">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </mat-card-header>
    <mat-card-content>
        <div class="pt-4 pb-3">
            <mat-form-field *ngIf="authService.user.type === 'admin'" class="three-quarters-width" appearance="outline">
                <mat-label>Select employee:</mat-label>
                <mat-select [(value)]="authService.userID" (selectionChange)="loadSelectedEmployeeWorkingTimes()">
                    @for (employee of employees; track employee) {
                        <mat-option [value]="employee.id">{{ employee.company_name }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
            <mat-accordion *ngIf="userID">
                <mat-expansion-panel class="bg-white" (opened)="cleanFilter()" (closed)="cleanFilter()">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <i class="fa-solid fa-filter text-primary-color mx-2"></i> Filter
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-form-field appearance="outline" color="primary">
                        <mat-label>Start date</mat-label>
                        <input matInput [matDatepicker]="startPicker" [formControl]="date">
                        <mat-hint>DD/MM/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                        <mat-datepicker #startPicker (closed)="setSearchFromDate()"></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field class="py-2 mx-1 mx-xl-3" appearance="outline" color="primary">
                        <mat-label>End date</mat-label>
                        <input matInput [matDatepicker]="endPicker" [formControl]="date">
                        <mat-hint>DD/MM/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                        <mat-datepicker #endPicker (closed)="setSearchToDate()"></mat-datepicker>
                    </mat-form-field>
                    <button mat-stroked-button color="primary" (click)="searchByDate()"
                            [disabled]="dateFrom === '' || dateTo === ''">
                        <mat-icon fontIcon="search"></mat-icon>
                        search
                    </button>
                    <button mat-stroked-button class="mx-2" color="primary" (click)="cleanFilter()">
                        <mat-icon fontIcon="cleaning_services"></mat-icon>
                        clear
                    </button>
                </mat-expansion-panel>
            </mat-accordion>
        </div>

        <div *ngIf="userID" class="table-container" >
            <table mat-table [dataSource]="workingTimes$" matSort (matSortChange)="sortData($event)"
                   matSortActive="start"
                   matSortDisableClear matSortDirection="desc">

                <!-- working time start column -->
                <ng-container matColumnDef="start">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="start">start</th>
                    <td mat-cell
                        *matCellDef="let workingTime">{{ workingTime.start | date: 'dd/MM/yyyy HH:mm' }}
                    </td>
                </ng-container>

                <!-- working time end column -->
                <ng-container matColumnDef="end">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="end">end</th>
                    <td mat-cell *matCellDef="let workingTime">{{ workingTime.end | date:'dd/MM/y HH:mm' }}</td>
                </ng-container>

                <!-- number of working hours column -->
                <ng-container matColumnDef="total_hours">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="total_hours">number of hours</th>
                    <td mat-cell *matCellDef="let workingTime">
                        <ng-container *ngIf="workingTime.total_hours">
                            {{ workingTime.total_hours }}h<span
                            *ngIf="workingTime.total_minutes">{{ workingTime.total_minutes }}m</span>
                        </ng-container>
                    </td>
                </ng-container>

                <!-- working time edit column -->
                <ng-container matColumnDef="actions">
                    <th class="table-cell-width" mat-header-cell *matHeaderCellDef>actions</th>
                    <td mat-cell *matCellDef="let workingTime">
                        <div class="d-flex">
                            <ng-container *ngIf="workingTime.end else editWorking">
                                <button mat-mini-fab color="indigo" matTooltip="Edit"
                                        (click)="showWorkingTime(workingTime.id)"
                                        matTooltipPosition="left" aria-label="Edit">
                                    <mat-icon>remove_red_eye</mat-icon>
                                </button>
                            </ng-container>
                            <ng-template #editWorking>
                                <button mat-mini-fab matTooltip="Edit"
                                        (click)="editWorkingTime(workingTime.id)"
                                        matTooltipPosition="left" aria-label="Edit">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </ng-template>
                            <button class="mx-2" mat-mini-fab color="warn" matTooltip="Delete"
                                    (click)="removeWorkingTime(workingTime.id)"
                                    matTooltipPosition="right" aria-label="Delete">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
        <div *ngIf="userID" class="pt-2">
            <h5 class="pt-1"><i class="fa-solid fa-clock text-primary-color"></i> TotalTime:
                <b class="text-primary-color">{{ totalWorkingTimeHours }}h</b>
                <span *ngIf="totalWorkingTimeMinutes">
                    <b class="text-primary-color">{{ totalWorkingTimeMinutes }}m</b>
                </span>
            </h5>
        </div>
        <mat-paginator class="bg-white" [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
                       [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)">
        </mat-paginator>
        <ng-container *ngIf="!workingTimes$.data.length">
            <div class="text-center py-5">
                <p>No working times found.</p>
            </div>
        </ng-container>
        <div class="mt-3" *ngIf="isLoading">
            <mat-progress-bar mode="indeterminate" *ngIf="isLoading" color="primary"></mat-progress-bar>
        </div>
    </mat-card-content>
</mat-card>
