import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

// models
import {WaterMeter} from "../models/water-protocol/water-meter";
import {WaterMeterData} from "../models/water-protocol/water-meter-data";
import {ApiMessage} from "../models/api-message";


@Injectable({
    providedIn: 'root'
})
export class WaterMeterService {

    public meters: WaterMeter[] = [];

    constructor(protected http: HttpClient) {
    }

    // add water meter to water meters
    public addWaterMeter(meter: WaterMeter): void {
        this.meters.push(meter);
    }

    // remove one water meter
    removeWaterMeter(index: number): void {
        this.meters.splice(index, 1);
    }

    // clear all water meters
    public clearWaterMeters(): void {
        this.meters = [];
    }

    // get water meter time form API - admin/member
    public getWaterMeter(userType: string, id: number): Observable<WaterMeter> {
        const url = this.getURLbyUserType(userType);
        return this.http.get<WaterMeter>(`${url}/${id}`);
    }

    // update water meter in DB for logged admin/member only
    public updateWaterMeter(userType: string, id: number, data: Partial<WaterMeterData>): Observable<ApiMessage> {
        const url = this.getURLbyUserType(userType);
        return this.http.put<ApiMessage>(`${url}/${id}`, data);
    }

    // get API URL by a logged admin/member
    private getURLbyUserType(userType: string): string {
        let url = '';
        switch (userType) {
            case 'admin':
                url = `${environment.api}/admin-water-meter`;
                break;
            case 'member':
                url = `${environment.api}/member-water-meter`;
                break;
        }
        return url;
    }
}
