<div class="pt-2">
    <div *ngIf="isLoading; else content">
        <div class="d-flex justify-content-center py-2">
            <mat-spinner></mat-spinner>
        </div>
    </div>
    <ng-template #content>
        <form [formGroup]="addressForm">
            <mat-form-field class="half-width mt-2" appearance="outline">
                <mat-label>Phone number:</mat-label>
                <input matInput formControlName="phone_number" name="phone_number" type="text"
                       placeholder="Phone number"
                       #phoneNumber>
                <mat-hint align="end">
                    {{ phoneNumber.value.length }} / {{ phoneNumberMaxLength }}
                </mat-hint>
                <mat-error *ngIf="phone_number.errors?.['required']">
                    Please enter the phone number.
                </mat-error>
                <mat-error
                    *ngIf="phone_number.value.length < phoneNumberMinLength && !phone_number.errors?.['required']">
                    A minimum length of the phone number is {{ phoneNumberMinLength }} characters.
                </mat-error>
                <mat-error *ngIf="phone_number.value.length > phoneNumberMaxLength">
                    A maximum length of the phone number is {{ phoneNumberMaxLength }} characters.
                </mat-error>
            </mat-form-field>
            <div class="mt-2">
                <h4>Address:</h4>
                <mat-form-field class="three-quarters-width" appearance="outline">
                    <mat-label>Resident name:</mat-label>
                    <input matInput formControlName="name" name="name" type="text" placeholder="Resident name"
                           #residentName>
                    <mat-hint align="end">
                        {{ residentName.value.length }} / {{ nameMaxLength }}
                    </mat-hint>
                    <mat-error *ngIf="name.errors?.['required']">
                        Please enter the resident name.
                    </mat-error>
                    <mat-error *ngIf="name.value.length < nameMinLength && !name.errors?.['required']">
                        A minimum length of the resident name is {{ nameMinLength }} characters.
                    </mat-error>
                    <mat-error *ngIf="name.value.length > nameMaxLength">
                        A maximum length of the resident name is {{ nameMaxLength }} characters.
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="three-quarters-width mt-2" appearance="outline">
                    <mat-label>Street name:</mat-label>
                    <input matInput formControlName="street_name" name="street_name" type="text"
                           placeholder="Street name" #streetName>
                    <mat-hint align="end">
                        {{ streetName.value.length }} / {{ streetNameMaxLength }}
                    </mat-hint>
                    <mat-error *ngIf="street_name.errors?.['required']">
                        Please enter the street name.
                    </mat-error>
                    <mat-error
                        *ngIf="street_name.value.length < streetNameMinLength && !street_name.errors?.['required']">
                        A minimum length of the street name is {{ streetNameMinLength }} characters.
                    </mat-error>
                    <mat-error *ngIf="street_name.value.length > streetNameMaxLength">
                        A maximum length of the street name is {{ streetNameMaxLength }} characters.
                    </mat-error>
                </mat-form-field>
                <mat-form-field style="width: 30%" appearance="outline">
                    <mat-label>Street number:</mat-label>
                    <input matInput formControlName="street_number" name="street_number" type="text"
                           placeholder="Street number" #streetNumber>
                    <mat-hint align="end">
                        {{ streetNumber.value.length }} / {{ streetNumberMaxLength }}
                    </mat-hint>
                    <mat-error *ngIf="street_number.errors?.['required']">
                        Please enter the street number.
                    </mat-error>
                    <mat-error
                        *ngIf="street_number.value.length < streetNumberMinLength && !street_number.errors?.['required']">
                        A minimum length of the street number is {{ streetNumberMinLength }} characters.
                    </mat-error>
                    <mat-error *ngIf="street_number.value.length > streetNumberMaxLength">
                        A maximum length of the street number is {{ streetNumberMaxLength }} characters.
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="mx-2" style="width: 30%" appearance="outline">
                    <mat-label>Apartment number:</mat-label>
                    <input matInput formControlName="apartment_number" name="apartment_number" type="text"
                           placeholder="Apartment number">
                </mat-form-field>
                <mat-form-field class="mt-2" style="width: 50%" appearance="outline">
                    <mat-label>City Name:</mat-label>
                    <input matInput formControlName="city_name" name="city_name" type="text"
                           placeholder="City Name" #cityName>
                    <mat-hint align="end">
                        {{ cityName.value.length }} / {{ cityNameMaxLength }}
                    </mat-hint>
                    <mat-error *ngIf="city_name.errors?.['required']">
                        Please enter the city name.
                    </mat-error>
                    <mat-error
                        *ngIf="city_name.value.length < cityNameMinLength && !city_name.errors?.['required']">
                        A minimum length of the city name is {{ cityNameMinLength }} characters.
                    </mat-error>
                    <mat-error *ngIf="city_name.value.length > cityNameMaxLength">
                        A maximum length of the city name is {{ cityNameMaxLength }} characters.
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="mt-2 mx-2" style="width: 30%" appearance="outline">
                    <mat-label>Zip Code:</mat-label>
                    <input matInput formControlName="zip_code" name="zip_code" type="text"
                           placeholder="Zip Code" #zipCode>
                    <mat-hint align="end">
                        {{ zipCode.value.length }} / {{ zipCodeMaxLength }}
                    </mat-hint>
                    <mat-error *ngIf="zip_code.errors?.['required']">
                        Please enter the zip code.
                    </mat-error>
                    <mat-error
                        *ngIf="zip_code.value.length < zipCodeMinLength && !zip_code.errors?.['required']">
                        A minimum length of the zip code is {{ zipCodeMinLength }} characters.
                    </mat-error>
                    <mat-error *ngIf="zip_code.value.length > zipCodeMaxLength">
                        A maximum length of the zip code is {{ zipCodeMaxLength }} characters.
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="mt-2" style="width: 30%" appearance="outline">
                    <mat-label>Intercom Code:</mat-label>
                    <input matInput formControlName="intercom_code" name="intercom_code" type="text"
                           placeholder="Intercom Code" #zipCode>
                    <mat-hint align="end">
                        {{ zipCode.value.length }} / {{ intercomCodeMaxLength }}
                    </mat-hint>
                    <mat-error *ngIf="intercom_code.errors?.['required']">
                        Please enter the intercom code.
                    </mat-error>
                    <mat-error
                        *ngIf="intercom_code.value.length < intercomCodeMinLength && !intercom_code.errors?.['required']">
                        A minimum length of the intercom code is {{ intercomCodeMinLength }} characters.
                    </mat-error>
                    <mat-error *ngIf="intercom_code.value.length > intercomCodeMaxLength">
                        A maximum length of the intercom code is {{ intercomCodeMaxLength }} characters.
                    </mat-error>
                </mat-form-field>
            </div>
        </form>
    </ng-template>
</div>
