import {Component} from '@angular/core';

@Component({
    selector: 'app-service-orders-archive',
    templateUrl: './service-orders-archive.component.html',
    styleUrl: './service-orders-archive.component.scss'
})
export class ServiceOrdersArchiveComponent {

}
