import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-form-save-button',
    templateUrl: './form-save-button.component.html',
    styleUrl: './form-save-button.component.scss'
})
export class FormSaveButtonComponent {

    @Input() public invalid = true;
    @Input() public showMode = false;

    @Output() public save = new EventEmitter<void>();
    @Output() public closeForm = new EventEmitter<void>();

    public saveEmit(): void {
        this.save.emit();
    }

    public closeEmit(): void {
        this.closeForm.emit();
    }
}
