import {Component, EventEmitter, Input, Output} from '@angular/core';

// service
import {SnackbarService} from "../../../shared/services/snackbar.service";

// models
import {ServiceOrderImageData} from "../../../shared/models/service-orders/service-order-image-data";


@Component({
    selector: 'app-service-order-images-form',
    templateUrl: './service-order-images-form.component.html',
    styleUrl: './service-order-images-form.component.scss'
})
export class ServiceOrderImagesFormComponent {

    @Input() public productID = 0;
    @Input() public progress = '';
    @Input() public resultClass = '';

    @Output() public fileToUpload = new EventEmitter<ServiceOrderImageData[]>;
    @Output() public imageDataReset = new EventEmitter<void>;
    @Output() public isLoading = new EventEmitter<boolean>;
    @Output() public cancel = new EventEmitter<void>;

    public imagePreviews: string[] = [];
    public selectedFiles: ServiceOrderImageData[] = [];

    constructor(private snackbarService: SnackbarService) {
    }

    public addImage(fileInput: HTMLInputElement): void {
        this.resetImageData();
        fileInput.value = "";
        fileInput.click();
    }

    public onFileSelected(fileInput: HTMLInputElement): void {
        const files: FileList = fileInput.files as FileList;
        for (let i = 0; i < files.length; i++) {
            if (this.validateImageQuantity(files.length)) {
                this.snackbarService.snackbarCustomError('You are selecting too many images!');
                this.resetImageData();
                return
            }
            if (files[i]) {
                if (!this.validateImageType(files[i].name)) {
                    this.snackbarService.snackbarCustomError('Selected image format is not supported!');
                    this.resetImageData();
                    return
                }
                if (!this.validateImageSize(files[i].size)) {
                    this.snackbarService.snackbarCustomError('Selected image size is to big!');
                    this.resetImageData();
                    return
                }
                const fileToSend: ServiceOrderImageData = {
                    file: files[i],
                };
                this.selectedFiles.push(fileToSend);
            }
        }
        this.generateImagePreviews(this.selectedFiles);
        this.fileToUpload.emit(this.selectedFiles);
    }

    public generateImagePreviews(files: ServiceOrderImageData[]): void {
        for (let i = 0; i < files.length; i++) {
            const reader: FileReader = new FileReader();
            reader.onload = (event: ProgressEvent<FileReader>): void => {
                if (typeof event.target?.result === "string")
                    this.imagePreviews.push(event.target.result);
            };
            reader.readAsDataURL(files[i].file);
        }
    }

    public imageRemove(imageID: number): void {
        const selectedFilesTemp: ServiceOrderImageData[] = this.selectedFiles;
        selectedFilesTemp.splice(imageID, 1);
        this.resetImageData();
        this.selectedFiles = selectedFilesTemp;
        this.generateImagePreviews(this.selectedFiles);
        if (this.selectedFiles.length > 0) {
            this.fileToUpload.emit(this.selectedFiles);
        }
    }

    public resetImageData(): void {
        this.selectedFiles = [];
        this.imageDataReset.emit();
        this.imagePreviews = [];
        this.resultClass = '';
        this.progress = '';
    }

    public cancelEmit(): void {
        this.resetImageData();
        this.cancel.emit();
        this.snackbarService.snackbarCustomWarning('Canceled!');
    }

    private validateImageType(name: string): boolean {
        const ext = name.substring(name.lastIndexOf('.') + 1);
        const allImages: string[] = ['jpg', 'jpeg', 'png', 'webp'];
        return allImages.indexOf(ext) > -1
    }

    private validateImageSize(size: number): boolean {
        return size <= 8192000;
    }

    private validateImageQuantity(quantity: number): boolean {
        return quantity > 10;
    }
}
