import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AddressFormComponent} from "./address-form/address-form.component";

// services
import {AddressService} from "../../../shared/services/address.service";

// models
import {ServiceOrderAddresses} from "../../../shared/models/service-orders/service-order-addresses";
import {ApiMessage} from "../../../shared/models/api-message";
import {SnackbarService} from "../../../shared/services/snackbar.service";

@Component({
    selector: 'app-service-order-address-edit',
    templateUrl: './service-order-address-edit.component.html',
    styleUrl: './service-order-address-edit.component.scss'
})
export class ServiceOrderAddressEditComponent implements OnInit {

    @ViewChild('data') data!: AddressFormComponent;

    public isLoading = false;
    public addressID = 0;
    public serviceOrderAddress!: ServiceOrderAddresses;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private addressService: AddressService,
                private snackbarService: SnackbarService) {
    }

    ngOnInit(): void {
        this.addressID = Number(this.route.snapshot.paramMap.get('id'));
        this.getServiceOrderAddress(this.addressID);
    }

    public getServiceOrderAddress(addressID: number): void {
        this.addressService.getAddress(addressID).subscribe((address: ServiceOrderAddresses): void => {
            this.serviceOrderAddress = address;
        })
    }

    public closeForm(): void {
        this.router.navigateByUrl(`secure/service-order/${this.serviceOrderAddress.service_order_id}`).then();
    }

    public updateAddress(): void {
        this.addressService.update(this.addressID, this.data.addressForm.getRawValue()).subscribe({
            next: (res: ApiMessage): void => {
                this.snackbarService.snackbarCustomComplete(res.message);
            },
            error: (e) => this.snackbarService.snackbarCustomError(e.error.message),
            complete: () => this.closeForm()
        })
    }
}
