<ng-container *ngIf="!progress else progressBar">
    <div class="py-4">
        <button class="mx-2" mat-raised-button color="indigo" matTooltip="Add Image(s)"
                matTooltipPosition="right" aria-label="Add Image(s)" (click)="addImage(fileInput)">
            {{ imagePreviews.length ? 'Change Image(s)' : 'Add Image(s)' }}
            <mat-icon>add_a_photo</mat-icon>
        </button>
        <input hidden type="file" class="form-control" name="images[]" accept="image/*"
               (change)="onFileSelected(fileInput)" #fileInput multiple/>
    </div>
    <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
        <ng-container *ngFor='let preview of imagePreviews; index as i'>
            <app-image-card [imageURL]="preview" [imageID]="i"
                            [userType]="'admin'" [editMode]="true"
                            (imageRemove)="imageRemove($event)">
            </app-image-card>
        </ng-container>
    </div>
</ng-container>
<ng-template #progressBar>
    <h5>Uploading image files. Please wait...</h5>
    <div class="progress my-4">
        <div class="progress-bar {{ resultClass }}" [style.width]="progress"> {{ progress }}</div>
    </div>
    <button class="mx-2" mat-stroked-button (click)="cancelEmit()">
        <i class="fa-solid fa-rotate-left"></i> Cancel
    </button>
</ng-template>
