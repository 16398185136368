import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {
    ServiceOrderCompletedModalComponent
} from "../service-order-completed-modal/service-order-completed-modal.component";

// models
import {ServiceOrder} from "../../../../shared/models/service-orders/service-order";

@Component({
    selector: 'app-service-order-status-edit',
    templateUrl: './service-order-status-edit.component.html',
    styleUrl: './service-order-status-edit.component.scss'
})
export class ServiceOrderStatusEditComponent {

    @Input() public serviceOrder!: ServiceOrder;
    @Input() public userType = '';
    @Input() public statusReady = '';

    @Output() public status = new EventEmitter();

    public editMode = false;
    public statusForm!: FormGroup;

    constructor(private formBuilder: FormBuilder,
                private dialog: MatDialog) {
    }

    public get orderStatus(): AbstractControl {
        return this.statusForm.controls['status'];
    }

    public changeEditMode(): void {
        this.editMode = !this.editMode;
        this.buildStatusForm();
        this.orderStatus.setValue(this.serviceOrder.status);
    }

    public updateOrderStatus(): void {
        this.status.emit(this.statusForm.getRawValue());
        this.editMode = !this.editMode;
    }

    public setStatusComplete(): void {
        this.dialog.open(ServiceOrderCompletedModalComponent, {
            data: {
                serviceOrderID: this.serviceOrder.id
            }
        }).afterClosed().subscribe(
            (res): void => {
                if (!res) {
                    this.orderStatus.setValue('completed');
                    this.updateOrderStatus();
                }
            }
        );
    }

    private buildStatusForm(): void {
        this.statusForm = this.formBuilder.group({
            status: ['']
        });
    }
}
