<div class="py-2 px-2 py-md-5 px-md-5">
    <div class="row">
        <div class="col-md-6">
            <mat-card class="bg-white mat-elevation-z3">
                <div class="d-flex flex-row-reverse link-secondary mx-2 mt-2" (click)="closeForm()"
                     style="cursor: pointer" (keydown.backspace)="closeForm()" role="button" aria-hidden="true">
                    <mat-icon aria-hidden="false" matTooltip="Close" matTooltipPosition="below">close</mat-icon>
                </div>
                <div class="col-9 header">
                    <mat-card-header>
                        <mat-card-title><i class="fa-solid fa-droplet text-primary-color"></i>
                            Water Protocol Add
                        </mat-card-title>
                        <mat-card-subtitle>Add a new water protocol.</mat-card-subtitle>
                    </mat-card-header>
                </div>
                <mat-card-content>
                    <div class="pt-4">
                        <p>Service Order ID: <b class="text-primary-color">{{ serviceOrderID }}</b></p>
                        <ng-container *ngIf="waterMeterService.meters.length">
                            <app-water-meter [waterMeters]="waterMeterService.meters" [deleteMode]="true"
                                             (delete)="removeWaterMeter($event)"></app-water-meter>
                        </ng-container>
                        <div *ngIf="!addWaterMeterMode && waterMeterService.meters.length < 2">
                            <button class="mx-2"
                                    mat-raised-button color="indigo"
                                    matTooltip="Save"
                                    matTooltipPosition="below" (click)="changeWaterMeterAddMode()">
                                <i class="fa-solid fa-plus"></i> Add WaterMeter
                            </button>
                        </div>
                        <app-water-meter-form *ngIf="addWaterMeterMode" (closeForm)="closeAddWaterMeter()"
                                              #meter></app-water-meter-form>
                        <ng-container *ngIf="!addWaterMeterMode && waterMeterService.meters.length >= 2">
                            <app-water-protocol-form #data></app-water-protocol-form>
                        </ng-container>
                        <app-save-button *ngIf="!addWaterMeterMode" [invalid]="waterMeterService.meters.length <= 1"
                                         (closeForm)="closeForm()"
                                         (save)="addWaterProtocol()"></app-save-button>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
