import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatSort, Sort} from "@angular/material/sort";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";

// services
import {AuthService} from "../../../shared/services/auth.service";
import {WorkingTimeService} from "../../../shared/services/working-time.service";

// models
import {WorkingTimes} from "../../../shared/models/working-time/working-times";
import {WorkingTime} from "../../../shared/models/working-time/working-time";

// date picker
import {FormControl} from "@angular/forms";
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import _moment from 'moment';
import {Router} from "@angular/router";
import {DeleteModalComponent} from "../../components/delete-modal/delete-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {User} from "../../../shared/models/user/user";

const moment = _moment;

export const DATE_FORMAT = {
    parse: {
        dateInput: ['YYYY-MM-DD']
    },
    display: {
        dateInput: 'DD/MM/Y',
        monthYearLabel: 'DD MM Y',
        dateA11yLabel: 'DD MM Y',
        monthYearA11yLabel: 'DD MM Y',
    },
};


@Component({
    selector: 'app-working-times-list',
    templateUrl: './working-times-list.component.html',
    styleUrls: ['./working-times-list.component.scss', './../../secure.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        {provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT},
    ],
})
export class WorkingTimesListComponent implements OnInit, AfterViewInit {

    @ViewChild(MatSort) public sort!: MatSort;
    @ViewChild(MatPaginator) public paginator!: MatPaginator;

    public isLoading = false;
    public displayedColumns: string[] = ['start', 'end', 'total_hours', 'actions'];
    public workingTimes$ = new MatTableDataSource<WorkingTime>();
    public pageSizeOptions: number[] = [6, 12, 24, 48];
    public totalRows = 0;
    public pageSize = 6;
    public currentPage = 0;
    public workingTimesSort = 'id';
    public workingTimesDirection = 'desc';

    public dateFrom: string | undefined = '';
    public dateTo: string | undefined = '';

    public employees!: User[];
    public userID = 0;

    public totalWorkingTimeHours = 0;
    public totalWorkingTimeMinutes = 0;

    public date = new FormControl<_moment.Moment | null>(moment());

    constructor(protected authService: AuthService,
                private router: Router,
                private workingTimesService: WorkingTimeService,
                private dialog: MatDialog) {
    }

    ngAfterViewInit(): void {
        this.workingTimes$.paginator = this.paginator;
        this.paginator.firstPage();
    }

    ngOnInit(): void {
        if (this.authService.user.type === 'admin') {
            this.getEmployees();
            if (this.authService.userID) this.loadSelectedEmployeeWorkingTimes();
        } else {
            this.userID = this.authService.user.id;
            this.loadWorkingTimes(this.userID, this.dateFrom, this.dateTo);
        }
    }

    public pageChanged(event: PageEvent): void {
        this.pageSize = event.pageSize;
        this.currentPage = event.pageIndex + 1;
        this.loadWorkingTimes(this.userID, this.dateFrom, this.dateTo);
    }

    public setSearchFromDate(): void {
        this.dateFrom = this.date.getRawValue()?.format('YYYY-MM-DD').toString();
    }

    public setSearchToDate(): void {
        this.dateTo = this.date.getRawValue()?.format('YYYY-MM-DD').toString();
    }

    public searchByDate(): void {
        this.loadWorkingTimes(this.userID, this.dateFrom, this.dateTo);
        this.paginator.firstPage();
    }

    public cleanFilter(): void {
        this.dateFrom = '';
        this.dateTo = '';
        this.date.setValue(moment());
        this.loadWorkingTimes(this.userID);
        this.paginator.firstPage();
    }

    public sortData(sort: Sort): void {
        switch (sort.active) {
            case 'start':
                this.workingTimesSort = sort.active;
                this.workingTimesDirection = sort.direction;
                this.loadWorkingTimes(this.userID, this.dateFrom, this.dateTo);
                return
            case 'end':
                this.workingTimesSort = sort.active;
                this.workingTimesDirection = sort.direction;
                this.loadWorkingTimes(this.userID, this.dateFrom, this.dateTo);
                return
            case 'total_hours':
                this.workingTimesSort = sort.active;
                this.workingTimesDirection = sort.direction;
                this.loadWorkingTimes(this.userID, this.dateFrom, this.dateTo);
                return
            default:
                return
        }
    }

    public addWorkingTime(): void {
        let userID;
        if (this.authService.user.type === 'admin') {
            userID = this.authService.userID;
        } else userID = this.authService.user.id;

        this.router.navigate(['secure/working-time-add'],
            {
                queryParams: {userID: userID},
                queryParamsHandling: 'merge'
            }
        ).then();
    }

    public showWorkingTime(id: number): void {
        this.router.navigateByUrl(`secure/working-time-show/${id}`).then();
    }

    public editWorkingTime(id: number): void {
        this.router.navigateByUrl(`secure/working-time-update/${id}`).then();
    }

    public removeWorkingTime(workingTimeID: number): void {
        this.dialog.open(DeleteModalComponent, {
            data: {
                modalService: 'WorkingTime',
                modalName: 'working time',
                id: workingTimeID
            }
        }).afterClosed().subscribe(
            (res): void => {
                if (this.authService.user.type === 'admin') {
                    this.loadSelectedEmployeeWorkingTimes();
                } else {
                    this.loadWorkingTimes(this.userID, this.dateFrom, this.dateTo);
                }
                if (!res) {
                    this.router.navigateByUrl(`secure/working-times`).then();
                }
            }
        );
    }

    public getEmployees(): void {
        this.authService.employee().subscribe((employees: User[]): void => {
            this.employees = employees;
        })
    }

    public loadSelectedEmployeeWorkingTimes(): void {
        this.userID = this.authService.userID;
        this.loadWorkingTimes(this.authService.userID, this.dateFrom, this.dateTo);
    }

    private getTotalWorkingTime(workingTime: WorkingTime[]): void {
        this.totalWorkingTimeHours = 0;
        this.totalWorkingTimeMinutes = 0;

        let minutesTMP = 0;
        for (const i in workingTime) {
            this.totalWorkingTimeHours += workingTime[i].total_hours;
            minutesTMP += workingTime[i].total_minutes;
        }
        const hours = Math.floor(minutesTMP / 60);
        const minutes = minutesTMP % 60;

        this.totalWorkingTimeHours = this.totalWorkingTimeHours + hours;
        this.totalWorkingTimeMinutes = minutes;
    }

    private loadWorkingTimes(userID: number, dateFrom?: string, dateTo?: string): void {
        this.isLoading = true;
        const userType = this.authService.user.type;
        this.workingTimesService.index(
            userType,
            userID,
            this.currentPage,
            this.pageSize,
            this.workingTimesSort,
            this.workingTimesDirection,
            dateFrom,
            dateTo,
        )
            .subscribe(
                (workingTimes: WorkingTimes): void => {
                    this.getTotalWorkingTime(workingTimes.data);
                    this.workingTimes$ = new MatTableDataSource(workingTimes.data);
                    this.workingTimes$.sort = this.sort;
                    this.paginator.length = workingTimes.meta.total;
                    this.paginator.pageIndex = 0;
                    this.paginator.pageIndex = workingTimes.meta.current_page - 1;
                    this.isLoading = false;
                }
            );
    }
}
