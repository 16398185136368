import {Component, Input, OnInit} from '@angular/core';
import {IntercomCode} from "../../../shared/models/intercom-codes/intercom-code";
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";


@Component({
    selector: 'app-intercom-code-form',
    templateUrl: './intercom-code-form.component.html',
    styleUrl: './intercom-code-form.component.scss'
})
export class IntercomCodeFormComponent implements OnInit {

    @Input() public editMode = false;
    @Input() public intercomCode!: IntercomCode;

    public isLoading = false;
    public intercomCodeForm!: FormGroup;
    public addressMinLength = 3;
    public addressMaxLength = 100;
    public numberMinLength = 1;
    public numberMaxLength = 100;
    public codeMinLength = 3;
    public codeMaxLength = 100;

    constructor(protected formBuilder: FormBuilder) {
    }

    ngOnInit(): void {
        this.buildIntercomCodeForm();
        if (this.editMode) {
            this.isLoading = true;
            setTimeout((): void => {
                this.setIntercomCode(this.intercomCode);
                this.isLoading = false;
            }, 500);
        }
    }

    public get address(): AbstractControl {
        return this.intercomCodeForm.controls['address'];
    }

    public get number(): AbstractControl {
        return this.intercomCodeForm.controls['number'];
    }

    public get code(): AbstractControl {
        return this.intercomCodeForm.controls['code'];
    }

    private buildIntercomCodeForm(): void {
        this.intercomCodeForm = this.formBuilder.group({
            address: ['', {
                validators: [Validators.required,
                    Validators.minLength(this.addressMinLength), Validators.maxLength(this.addressMaxLength)]
            }],
            number: ['', {
                validators: [Validators.required,
                    Validators.minLength(this.numberMinLength), Validators.maxLength(this.numberMaxLength)]
            }],
            code: ['', {
                validators: [Validators.required,
                    Validators.minLength(this.codeMinLength), Validators.maxLength(this.codeMaxLength)]
            }],
        });
    }

    private setIntercomCode(intercomCode: IntercomCode): void {
        const {...formData} = intercomCode;
        this.intercomCodeForm.patchValue(formData);
    }
}
