<div class="col">
    <div class="card card-image shadow-sm">
        <ng-container *ngIf="!editMode else edit">
            <img class="img-hover" [src]="imageURL" alt="preview" (click)="imageViewEmit(imageID)"
                 style="cursor: pointer" (keydown.enter)="imageViewEmit(imageID)" role="button"
                 aria-hidden="true">
        </ng-container>
        <ng-template #edit>
            <img [src]="imageURL" alt="preview">
        </ng-template>
        <div class="card-body">
            <div class="d-flex justify-content-end align-items-center">
                <button *ngIf="!editMode" class="mx-2" mat-stroked-button
                        (click)="imageViewEmit(imageID)">
                    <i class="fa-solid fa-eye"></i> View
                </button>
                <button *ngIf="userType === 'admin' && !isArchive" mat-stroked-button
                        color="warn" (click)="imageRemoveEmit(imageID)">
                    <i class="fa-solid fa-trash"></i>
                </button>
            </div>
        </div>
    </div>
</div>
