import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-image-card',
    templateUrl: './image-card.component.html',
    styleUrl: './image-card.component.scss'
})
export class ImageCardComponent {

    @Input() public imageURL = '';
    @Input() public userType = '';
    @Input() public imageID = 0;
    @Input() public editMode = false;
    @Input() public isArchive = false;

    @Output() public imageView = new EventEmitter<number>();
    @Output() public imageRemove = new EventEmitter<number>();

    public imageViewEmit(imageID: number): void {
        this.imageView.emit(imageID);
    }

    public imageRemoveEmit(imageID: number): void {
        this.imageRemove.emit(imageID);
    }
}
