<div class="pt-2">
    <form [formGroup]="waterProtocolForm">
        <mat-form-field class="full-width mt-2" appearance="outline">
            <mat-label>Water protocol comment:</mat-label>
            <textarea matInput formControlName="comments" name="comments" type="text"
                      placeholder="Water protocol comment"
                      #comment></textarea>
            <mat-hint align="end">
                {{ comment.value.length }} / {{ commentMaxLength }}
            </mat-hint>
            <mat-error *ngIf="comments.value.length > commentMaxLength">
                A maximum length of comment is {{ commentMaxLength }} characters.
            </mat-error>
        </mat-form-field>
    </form>
</div>
