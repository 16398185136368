<div class="pt-2">
    <div *ngIf="isLoading; else content">
        <div class="d-flex justify-content-center py-2">
            <mat-spinner></mat-spinner>
        </div>
    </div>
    <ng-template #content>
        <div *ngIf="!updateMode" class="mx-2">
            <h4><i class="fa-solid fa-stopwatch text-primary-color"></i> Add Water Meter:</h4>
        </div>
        <form [ngClass]="{'pt-2': updateMode}" [formGroup]="waterMeterForm">
            <mat-form-field class="three-quarters-width mt-2 mx-2" appearance="outline">
                <mat-label>Type:</mat-label>
                <mat-select formControlName="type">
                    <mat-option [value]="'hot'">hot</mat-option>
                    <mat-option [value]="'cold'">cold</mat-option>
                </mat-select>
            </mat-form-field>
            <ng-container *ngIf="type.value">
                <mat-form-field class="three-quarters-width mt-2 mx-2" appearance="outline">
                    <mat-label>Meter number:</mat-label>
                    <input matInput formControlName="number" name="number" type="text"
                           placeholder="Meter number"
                           #meterNumber>
                    <mat-hint align="end">
                        {{ meterNumber.value.length }} / {{ meterNumberMaxLength }}
                    </mat-hint>
                    <mat-error *ngIf="number.errors?.['required']">
                        Please enter the meter number.
                    </mat-error>
                    <mat-error
                        *ngIf="number.value.length < meterNumberMinLength && !number.errors?.['required']">
                        A minimum length of the meter number is {{ meterNumberMinLength }} characters.
                    </mat-error>
                    <mat-error *ngIf="number.value.length > meterNumberMaxLength">
                        A maximum length of the meter number is {{ meterNumberMaxLength }} characters.
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="three-quarters-width mt-2 mx-2" appearance="outline">
                    <mat-label>Meter counter number:</mat-label>
                    <input matInput formControlName="counter" name="counter" type="text"
                           placeholder="Meter counter number"
                           #meterCounterNumber>
                    <mat-hint align="end">
                        {{ meterCounterNumber.value.length }} / {{ meterCounterNumberMaxLength }}
                    </mat-hint>
                    <mat-error *ngIf="counter.errors?.['required']">
                        Please enter the meter counter number.
                    </mat-error>
                    <mat-error
                        *ngIf="counter.value.length < meterCounterNumberMinLength && !number.errors?.['required']">
                        A minimum length of the meter counter number is {{ meterCounterNumberMinLength }} characters.
                    </mat-error>
                    <mat-error *ngIf="counter.value.length > meterCounterNumberMaxLength">
                        A maximum length of the meter counter number is {{ meterCounterNumberMaxLength }} characters.
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="three-quarters-width mt-2 mx-2" appearance="outline">
                    <mat-label>Radio overlay number:</mat-label>
                    <input matInput formControlName="radio_overlay_number" name="radio_overlay_number" type="text"
                           placeholder="Radio overlay number"
                           #overlayNumber>
                    <mat-hint align="end">
                        {{ overlayNumber.value.length }} / {{ radioOverlayNumberMaxLength }}
                    </mat-hint>
                    <mat-error *ngIf="radioOverlayNumber.errors?.['required']">
                        Please enter the radio overlay number.
                    </mat-error>
                    <mat-error
                        *ngIf="radioOverlayNumber.value.length < radioOverlayNumberMinLength && !number.errors?.['required']">
                        A minimum length of the radio overlay number is {{ radioOverlayNumberMinLength }} characters.
                    </mat-error>
                    <mat-error *ngIf="radioOverlayNumber.value.length > radioOverlayNumberMaxLength">
                        A maximum length of the radio overlay number is {{ radioOverlayNumberMaxLength }} characters.
                    </mat-error>
                </mat-form-field>
            </ng-container>
        </form>
    </ng-template>
</div>
<app-save-button [invalid]="waterMeterForm.invalid"
                 (closeForm)="closeFormEmit()"
                 (save)="addWaterMeter()"
                 [addMode]="!updateMode">
</app-save-button>
