import {Component, OnInit} from '@angular/core';
import {WorkingTimeService} from "../../../shared/services/working-time.service";
import {ActivatedRoute, Router} from "@angular/router";
import {WorkingTime} from "../../../shared/models/working-time/working-time";
import {AuthService} from "../../../shared/services/auth.service";

@Component({
    selector: 'app-working-time-show',
    templateUrl: './working-time-show.component.html',
    styleUrl: './working-time-show.component.scss'
})
export class WorkingTimeShowComponent implements OnInit {

    public isLoading = false;
    public workingTimeID = 0;
    public workingTime!: WorkingTime;

    constructor(protected authService: AuthService,
                private workingTimesService: WorkingTimeService,
                private router: Router,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.workingTimeID = Number(this.route.snapshot.paramMap.get('id'));
        this.getWorkingTime(this.workingTimeID);
    }

    public closeForm(): void {
        this.router.navigateByUrl('/secure/working-times').then();
    }

    public editWorkingTime(): void {
        this.router.navigateByUrl(`secure/working-time-update/${this.workingTimeID}`).then();
    }

    private getWorkingTime(id: number): void {
        this.isLoading = true;
        this.workingTimesService.getWorkingTime(this.authService.user.type, id).subscribe((workingTime: WorkingTime): void => {
            this.workingTime = workingTime;
            this.isLoading = false;
        })
    }
}
