<div class="rounded-3 mx-3"
     [ngClass]="{'bg-primary-subtle': message.user_id === 1, 'bg-light': message.user_id !== 1,
     'text-end': message.user_id === 1}">
    <p class="mx-1 text-small" [ngClass]="{'me-1': message.user_id === 1}">
        <b>{{ message.user_id === 1 ? 'Admin' : 'Me' }}</b> {{ message.date | date:'dd/MM/y hh:mm' }} </p>
    <div class="mb-2">{{ message.message }}</div>
</div>
<div class="d-flex justify-content-end my-2">
    <button *ngIf="checkLoginUserRead()" class="mx-2" mat-stroked-button (click)="readEmit(message.id)">
        <i class="fa-solid fa-thumbs-up"></i> read
    </button>
    <button *ngIf="deleteMode" class="mx-2" mat-mini-fab color="warn" (click)="removeMessage(message.id)"><i
        class="fa-solid fa-trash"></i>
    </button>
</div>
