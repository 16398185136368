import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

// services
import {ServiceOrderFormComponent} from "../service-order-form/service-order-form.component";
import {ServiceOrdersService} from "../../../shared/services/service-orders.service";
import {SnackbarService} from "../../../shared/services/snackbar.service";
import {ApiMessage} from "../../../shared/models/api-message";
import {AuthService} from "../../../shared/services/auth.service";
import {ServiceOrderData} from "../../../shared/models/service-orders/service-order-data";


@Component({
    selector: 'app-service-order-save',
    templateUrl: './service-order-save.component.html',
    styleUrl: './service-order-save.component.scss'
})
export class ServiceOrderSaveComponent implements OnInit {

    @ViewChild('data') data!: ServiceOrderFormComponent;

    public userID = 0;
    private serviceOrderDataTMP!: ServiceOrderData;

    constructor(protected authService: AuthService,
                private ordersService: ServiceOrdersService,
                private snackbarService: SnackbarService,
                private route: ActivatedRoute,
                private router: Router) {
    }

    ngOnInit(): void {
        if (Number(this.route.snapshot.paramMap.get('id'))) {
            this.userID = Number(this.route.snapshot.paramMap.get('id'));
        }
    }

    public addServiceOrder(): void {
        if (this.userID) {
            this.serviceOrderDataTMP = {
                user_id: this.userID,
                ...this.data.serviceOrderForm.getRawValue()
            }
        } else {
            this.serviceOrderDataTMP = this.data.serviceOrderForm.getRawValue();
        }
        this.ordersService.createServiceOrder(this.authService.user.type, this.serviceOrderDataTMP).subscribe({
            next: (res: ApiMessage): void => {
                this.snackbarService.snackbarCustomComplete(res.message);
            },
            error: (e) => this.snackbarService.snackbarCustomError(e.error.message),
            complete: () => this.closeForm()
        })
    }

    public closeForm(): void {
        this.router.navigateByUrl('/secure/service-orders').then();
    }
}
