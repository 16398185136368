import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

// models
import {ServiceOrderAddresses} from "../models/service-orders/service-order-addresses";
import {ServiceOrderAddressesData} from "../models/service-orders/service-order-addresses-data";
import {ApiMessage} from "../models/api-message";


@Injectable({
    providedIn: 'root'
})
export class AddressService {

    constructor(protected http: HttpClient) {
    }

    // get one service order address forom API - only for admin
    public getAddress(id: number): Observable<ServiceOrderAddresses> {
        return this.http.get<ServiceOrderAddresses>(`${environment.api}/address/${id}`);
    }

    // update one service order address in API - only for admin
    update(id: number, data: Partial<ServiceOrderAddressesData>): Observable<ApiMessage> {
        return this.http.put<ApiMessage>(`${environment.api}/address/${id}`, data);
    }
}
