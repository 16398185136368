import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {WaterProtocolFormComponent} from "./water-protocol-form/water-protocol-form.component";

// models
import {WaterProtocol} from "../../shared/models/water-protocol/water-protocol";
import {ApiMessage} from "../../shared/models/api-message";

// services
import {WaterProtocolService} from "../../shared/services/water-protocol.service";
import {DeleteModalComponent} from "../components/delete-modal/delete-modal.component";
import {AuthService} from "../../shared/services/auth.service";
import {ServiceOrdersService} from "../../shared/services/service-orders.service";
import {ServiceOrder} from "../../shared/models/service-orders/service-order";
import {SnackbarService} from "../../shared/services/snackbar.service";


@Component({
    selector: 'app-water-protocol',
    templateUrl: './water-protocol.component.html',
    styleUrl: './water-protocol.component.scss'
})
export class WaterProtocolComponent implements OnInit {

    @ViewChild('data') data!: WaterProtocolFormComponent;

    public isLoading = false;
    public isUpdating = false;
    public waterProtocolID = 0;
    public waterProtocol!: WaterProtocol;
    public isArchive = false;
    public editCommentMode = false;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private dialog: MatDialog,
                protected authService: AuthService,
                private waterProtocolService: WaterProtocolService,
                private snackbarService: SnackbarService,
                private ordersService: ServiceOrdersService) {
    }

    ngOnInit(): void {
        this.waterProtocolID = Number(this.route.snapshot.paramMap.get('id'));
        this.getWaterProtocol(this.waterProtocolID);
    }

    public getWaterProtocol(id: number): void {
        this.isLoading = true;
        this.waterProtocolService.getWaterProtocol(id).subscribe((waterProtocol: WaterProtocol): void => {
            this.waterProtocol = waterProtocol;
            if (this.waterProtocol) {
                this.ordersService.getServiceOrder(this.authService.user.type, this.waterProtocol.service_order_id).subscribe((serviceOrder: ServiceOrder): void => {
                    if (serviceOrder.status === 'completed') this.isArchive = true;
                    this.isLoading = false;
                })
            }
        })
    }

    public back(): void {
        this.router.navigateByUrl(`secure/service-order/${this.waterProtocol.service_order_id}`).then();
    }

    public switchEditCommentMode(): void {
        this.editCommentMode = !this.editCommentMode;
    }

    public updateComment(): void {
        this.isUpdating = true;
        this.waterProtocolService.updateWaterProtocol(this.authService.user.type, this.waterProtocolID, this.data.waterProtocolForm.getRawValue()).subscribe({
            next: (res: ApiMessage): void => {
                this.snackbarService.snackbarCustomComplete(res.message);
                this.waterProtocolService.getWaterProtocol(this.waterProtocolID).subscribe((waterProtocol: WaterProtocol): void => {
                    this.waterProtocol = waterProtocol;
                    this.isUpdating = false;
                })
            },
            error: (e) => this.snackbarService.snackbarCustomError(e.error.message),
            complete: () => this.switchEditCommentMode()
        })
    }

    public updateWaterMeter(waterMeterID: number): void {
        this.router.navigateByUrl(`secure/water-meter-update/${waterMeterID}`).then();
    }

    public delete(waterProtocolID: number): void {
        this.dialog.open(DeleteModalComponent, {
            data: {
                modalService: 'WaterProtocol',
                modalName: 'water protocol',
                id: waterProtocolID
            }
        }).afterClosed().subscribe(
            (res): void => {
                if (!res) {
                    this.router.navigateByUrl(`secure/service-order/${this.waterProtocol.service_order_id}`).then();
                }
            }
        );
    }
}
