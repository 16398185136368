import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatSort, Sort} from "@angular/material/sort";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {MatSelectChange} from "@angular/material/select";
import {Router} from "@angular/router";

// service
import {AuthService} from "../../../shared/services/auth.service";

// models
import {Users} from "../../../shared/models/user/users";
import {User} from "../../../shared/models/user/user";

@Component({
    selector: 'app-users-list',
    templateUrl: './users-list.component.html',
    styleUrls: ['./users-list.component.scss', './../../secure.component.scss']
})
export class UsersListComponent implements OnInit, AfterViewInit {

    @ViewChild(MatSort) public sort!: MatSort;
    @ViewChild(MatPaginator) public paginator!: MatPaginator;

    public isLoading = false;
    public displayedColumns: string[] = ['id', 'type', 'name', 'company_name', 'action'];
    public users$ = new MatTableDataSource<User>();
    public pageSizeOptions: number[] = [6, 12, 24, 48];
    public totalRows = 0;
    public pageSize = 6;
    public currentPage = 0;
    public usersSort = 'id';
    public usersDirection = 'desc';
    public typeFilter = '';

    constructor(private authService: AuthService,
                private router: Router) {
    }

    ngAfterViewInit(): void {
        this.users$.paginator = this.paginator;
        this.paginator.firstPage();
    }

    ngOnInit(): void {
        this.loadUsers();
    }

    public pageChanged(event: PageEvent): void {
        this.pageSize = event.pageSize;
        this.currentPage = event.pageIndex + 1;
        this.loadUsers();
    }

    public sortData(sort: Sort): void {
        switch (sort.active) {
            case 'id':
                this.usersSort = 'id';
                this.usersDirection = sort.direction;
                this.loadUsers();
                return
            case 'type':
                this.usersSort = sort.active;
                this.usersDirection = sort.direction;
                this.loadUsers();
                return
            case 'name':
                this.usersSort = sort.active;
                this.usersDirection = sort.direction;
                this.loadUsers();
                return
            case 'company_name':
                this.usersSort = sort.active;
                this.usersDirection = sort.direction;
                this.loadUsers();
                return
            default:
                return
        }
    }

    public setTypeFilter(option: MatSelectChange): void {
        this.typeFilter = option.value;
        this.loadUsers();
        this.paginator.firstPage();
    }

    public cleanFilter(): void {
        this.typeFilter = '';
        this.loadUsers();
        this.paginator.firstPage();
    }

    public addServiceOrder(id: number): void {
        this.router.navigateByUrl(`/secure/service-order-add/${id}`).then();
    }

    public editUser(id: number): void {
        this.router.navigateByUrl(`/secure/user-show/${id}`).then();
    }

    private loadUsers(): void {
        this.isLoading = true;
        this.authService.index(
            this.currentPage,
            this.pageSize,
            this.usersSort,
            this.usersDirection,
            this.typeFilter)
            .subscribe(
                (users: Users): void => {
                    this.users$ = new MatTableDataSource(users.data);
                    this.users$.sort = this.sort;
                    this.paginator.length = users.meta.total;
                    this.paginator.pageIndex = 0;
                    this.paginator.pageIndex = users.meta.current_page - 1;
                    this.isLoading = false;
                }
            );
    }
}
