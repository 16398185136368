import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {IntercomCodeFormComponent} from "../intercom-code-form/intercom-code-form.component";

//service
import {AuthService} from "../../../shared/services/auth.service";
import {IntercomCodesService} from "../../../shared/services/intercom-codes.service";
import {SnackbarService} from "../../../shared/services/snackbar.service";

// models
import {IntercomCode} from "../../../shared/models/intercom-codes/intercom-code";
import {ApiMessage} from "../../../shared/models/api-message";


@Component({
    selector: 'app-intercom-code-update',
    templateUrl: './intercom-code-update.component.html',
    styleUrl: './intercom-code-update.component.scss'
})
export class IntercomCodeUpdateComponent implements OnInit {

    @ViewChild('data') data!: IntercomCodeFormComponent;

    public isLoading = false;
    public intercomCodeID = 0;
    public intercomCode!: IntercomCode;

    constructor(protected authService: AuthService,
                private intercomCodesService: IntercomCodesService,
                private snackbarService: SnackbarService,
                private route: ActivatedRoute,
                private router: Router) {
    }

    ngOnInit(): void {
        this.intercomCodeID = Number(this.route.snapshot.paramMap.get('id'));
        this.getIntercomCode(this.intercomCodeID);
    }

    public addIntercomCode(): void {
        this.intercomCodesService.updateIntercomCode(this.authService.user.type, this.intercomCodeID, this.data.intercomCodeForm.getRawValue()).subscribe({
            next: (res: ApiMessage): void => {
                this.snackbarService.snackbarCustomComplete(res.message);
            },
            error: (e) => this.snackbarService.snackbarCustomError(e.error.message),
            complete: () => this.closeForm()
        })
    }

    public closeForm(): void {
        this.router.navigateByUrl('/secure/intercom-codes').then();
    }

    private getIntercomCode(id: number): void {
        this.isLoading = true;
        this.intercomCodesService.getIntercomCode(this.authService.user.type, id).subscribe((intercomCode: IntercomCode): void => {
            this.intercomCode = intercomCode;
            this.isLoading = false;
        })
    }
}
