import {Component} from '@angular/core';

@Component({
    selector: 'app-intercom-codes',
    templateUrl: './intercom-codes.component.html',
    styleUrl: './intercom-codes.component.scss'
})
export class IntercomCodesComponent {

}
